import { Pipe, PipeTransform } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';

import { LangchangePipe } from './langchange.pipe';

@Pipe({
  name: 'langTransform',
  pure: false,
})
export class LangTransform implements PipeTransform {
  transform(items: any[]): any {
    const translatedItems = [];
    if (items && items.length > 0) {
      items.forEach(x => {
        const translation = this.translate(x);
        if (x?.name) {
          x.name = translation;
        } else {
          x = translation;
        }
        translatedItems.push(x);
      });
    }
    return translatedItems;
  }

  translate(key: any): any {
    if (key?.name) {
      if (AppConstants.preferredLanguageData) {
        const transFromText = AppConstants.preferredLanguageData[key.name];
        if (transFromText && transFromText.value) {
          return transFromText.value;
        }
      }
      return key.name;
    } else {
      if (AppConstants.preferredLanguageData) {
        const transFromText = AppConstants.preferredLanguageData[key];
        if (transFromText && transFromText.value) {
          return transFromText.value;
        }
      }
      return key;
    }
  }
}
