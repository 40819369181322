<div *ngIf="show">
  <div class="status-indicator row">
    <div class="status-indicator-label row m-r-10">
      <p *ngIf="label">{{ label }}&nbsp;</p>
      <p>{{ 'title_Status' | langchange }}:</p>
    </div>
    <div class="status-indicator-status">
      <p class="status-indicator-status-{{ statusStr.toLowerCase() }}">{{ displayStatus(statusStr) }}</p>
    </div>
  </div>
</div>
