import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

import { CommonConstants } from '../../../common-components/constants/common-constants';
import {
  INotification,
  NotificationSystemService,
} from '../../../common-components/controllers/common-notification-system.controller';
import { ValidationFunctions } from '../../../common-components/utilities/common-validation-functions';
import { AppbarService } from '../../../components/appbar/appbar-service';
import { Roles } from '../../../constants/app-types';
import { AdminApprovalService } from '../../../controllers/admin/admin-approval.controller';
import { AttendantProfileService } from '../../../controllers/attendant/attendant-profile.controller';
import { EmailService } from '../../../controllers/email/email.controller';
import { UserService } from '../../../controllers/user/user.controller';
import {
  Attendant,
  EmailRequest,
  ReviewRequest,
} from '../../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';

@Component({
  selector: 'app-attendant-profile',
  templateUrl: 'attendant-profile.page.html',
  styleUrls: ['attendant-profile.page.scss'],
})
export class AttendantProfilePage implements OnInit, OnDestroy {
  public attendantData: Attendant;
  public contactForm: FormGroup;
  public contactData = {
    message: null,
    email: null,
  };
  public showMessageDialog = false;
  public showMessageSuccessDialog = false;
  public reviewed = false;
  public role: Roles;
  public roleButton = {
    isUser: true,
    isAttendant: false,
    isAdmin: false,
  };
  public status: ReviewRequest[];
  public statusPic: ReviewRequest;
  public statusAboutMe: ReviewRequest;
  public statusSnippet: ReviewRequest;
  public statusSelections = {
    photo: null,
    about: null,
    snippet: null,
    all: null,
  };
  private updateReqs: ReviewRequest[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private notificationSystemService: NotificationSystemService,
    private appbarService: AppbarService,
    private attendantProfileService: AttendantProfileService,
    private adminApprovalService: AdminApprovalService,
    private userService: UserService,
    private emailService: EmailService,
    private router: Router
  ) {
    this.adminApprovalService
      .getRequestsByID(undefined, false)
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        if (data !== undefined && this.attendantData) {
          this.status = data;
          data.forEach(x => {
            switch (x.type) {
              case 0:
                this.statusPic = x;
                this.attendantData.photoUrl = x.metadata;
                this.statusSelections.photo = x.status === 1;
                break;
              case 1:
                this.attendantData.resumeUrl = x.metadata;
                break;
              case 2:
                this.statusAboutMe = x;
                this.attendantData.about = x.metadata;
                this.statusSelections.about = x.status === 1;
                break;
              case 3:
                this.statusSnippet = x;
                this.attendantData.snippet = x.metadata;
                this.statusSelections.snippet = x.status === 1;
                break;
            }
          });
        }
      });

    this.userService
      .getRoleHighest()
      .pipe(takeUntil(this._destroying$))
      .subscribe((roleData: Roles) => {
        this.roleButton.isAdmin = false;
        this.roleButton.isAttendant = false;
        this.roleButton.isUser = true;
        this.role = roleData;
        if (roleData === 'ProviderDirectoryAdmin') {
          this.roleButton.isAdmin = true;
          this.roleButton.isUser = false;
        }
      });

    this.attendantProfileService
      .fetchPublicData(true)
      .pipe(takeUntil(this._destroying$))
      .subscribe(attendantData => {
        if (attendantData) {
          this.attendantData = attendantData;
          this.roleButton.isAttendant = false;
          this.roleButton.isUser = true;
          if (this.roleButton.isAttendant || this.roleButton.isAdmin) {
            this.adminApprovalService.getReviewRequest(attendantData.userId);
          }
          const id = this.userService.getIdNow();
          if (id) {
            if (attendantData.userId === id) {
              this.roleButton.isAttendant = true;
              this.roleButton.isUser = false;
              this.roleButton.isAdmin = false;
            }
          }
        }
      });

    this.appbarService
      .getAdminTrigger()
      .pipe(takeUntil(this._destroying$))
      .subscribe(func => {
        if (func && !this.reviewed) {
          switch (func) {
            case 'complete':
              this.adminComplete();
              this.reviewed = true;
              break;
            case 'cancel':
              this.adminCancel();
              this.reviewed = true;
              break;
            case 'delete':
              this.adminDelete();
              this.reviewed = true;
              break;
            case 'spam':
              this.adminSpam();
              this.reviewed = true;
              break;
            case 'refresh':
              this.languageRefresh();
              break;
          }
        }
      });
  }

  ngOnInit(): void {
    this.setPage();
    this.setForm();
  }

  ionViewWillEnter(): void {
    this.setPage();
    this.setForm();
    this.reviewed = false;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public setPage() {
    this.appbarService.clear();
    this.appbarService.setPage('attendant');
  }

  public setForm() {
    this.contactForm = new FormGroup({
      message: new FormControl(this.contactData.message, [Validators.required]),
      email: new FormControl(this.contactData.email, [Validators.required, ValidationFunctions.emailValidation]),
    });
  }

  public goEdit() {
    this.router.navigate(['/Attendant/Edit']);
  }

  public goBack() {
    window.history.back();
  }

  public setStatus(event) {
    if (event.about !== null && event.about !== undefined) {
      this.statusSelections.about = event.about;
    }
    if (event.snippet !== null && event.snippet !== undefined) {
      this.statusSelections.snippet = event.snippet;
    }
    if (event.photo !== null && event.photo !== undefined) {
      this.statusSelections.photo = event.photo;
    }
  }

  public showMessageModal() {
    this.showMessageDialog = true;
  }

  public closeMessageModal(str: string) {
    this.showMessageDialog = false;
    if (str === 'yes') {
      this.formToEntity();
      const newEmail = new EmailRequest();
      newEmail.emailTos = [this.attendantData.userId];
      newEmail.emailSubject = this.contactData.email;
      newEmail.template = CommonConstants.templates.providerEmail;
      newEmail.placeHolderInfo = { '{message}': this.contactData.message };
      this.emailService.notify(newEmail);
      this.showMessageSuccessModal();
    }
  }

  public showMessageSuccessModal() {
    this.showMessageSuccessDialog = true;
  }

  public closeMessageSuccessModal(str: string) {
    this.showMessageSuccessDialog = false;
  }

  public formToEntity() {
    Object.keys(this.contactForm.controls).forEach(key => {
      if (this.contactForm.controls[key].value?.value) {
        this.contactData[key] = this.contactForm.controls[key].value.value;
      } else {
        this.contactData[key] = this.contactForm.controls[key].value;
      }
    });
  }

  private adminComplete() {
    if (this.statusSelections['photo'] !== null && this.statusSelections['photo'] !== undefined && this.statusPic) {
      this.setReviewRequest(
        0,
        this.statusSelections['photo'] ? 1 : 2,
        this.statusPic.requestId,
        this.attendantData.photoUrl
      );
    }
    if (this.statusSelections['about'] !== null && this.statusSelections['about'] !== undefined && this.statusAboutMe) {
      this.setReviewRequest(
        2,
        this.statusSelections['about'] ? 1 : 2,
        this.statusAboutMe.requestId,
        this.attendantData.about
      );
    }
    if (
      this.statusSelections['snippet'] !== null &&
      this.statusSelections['snippet'] !== undefined &&
      this.statusSnippet
    ) {
      this.setReviewRequest(
        3,
        this.statusSelections['snippet'] ? 1 : 2,
        this.statusSnippet.requestId,
        this.attendantData.snippet
      );
    }

    if (
      this.statusSelections['photo'] !== false &&
      this.statusSelections['about'] !== false &&
      this.statusSelections['snippet'] !== false
    ) {
      this.attendantData.status = 'Approved';
      this.attendantData.approvalDate = new Date();
    } else if (
      this.statusSelections['photo'] === false &&
      this.statusSelections['about'] === false &&
      this.statusSelections['snippet'] === false
    ) {
      this.attendantData.status = 'Rejected';
      this.attendantData.approvalDate = null;
    } else {
      this.attendantData.status = 'Reviewed';
      this.attendantData.approvalDate = null;
    }
    this.attendantProfileService.update(this.attendantData, false);
    if (this.updateReqs.length > 0 && !this.reviewed) {
      this.adminApprovalService.updateReviewRequest(this.updateReqs);
      this.updateReqs = [];
    }
    const notification: INotification = { type: 'success', content: 'Profile Review Complete' };
    this.notificationSystemService.setNotification(notification);
    this.appbarService.clear();
    this.router.navigate(['/Admin/Attendants']);
  }

  private adminCancel() {
    const notification: INotification = { type: 'success', content: 'Profile Review Canceled' };
    this.notificationSystemService.setNotification(notification);
    this.appbarService.clear();
    this.router.navigate(['/Admin/Attendants']);
  }

  private adminDelete() {
    const notification: INotification = { type: 'success', content: 'Profile Status set as Deleted' };
    this.notificationSystemService.setNotification(notification);
    this.attendantData.status = 'Deleted';
    this.attendantProfileService.update(this.attendantData, false);
    this.appbarService.clear();
    this.router.navigate(['/Admin/Attendants']);
  }

  private adminSpam() {
    const notification: INotification = { type: 'success', content: 'Profile Status set as Spam' };
    this.notificationSystemService.setNotification(notification);
    this.attendantData.status = 'Spam';
    this.attendantProfileService.update(this.attendantData, false);
    this.appbarService.clear();
    this.router.navigate(['/Admin/Attendants']);
  }

  private setReviewRequest(type: number, status: number, requestId: number, data?: string) {
    const newReq = new ReviewRequest();
    newReq.requestId = requestId;
    newReq.type = type;
    newReq.metadata = data;
    newReq.status = status;
    newReq.revieweeId = this.attendantData.userId;
    newReq.reviewerId = this.userService.getIdNow();
    this.updateReqs.push(newReq);
  }

  private languageRefresh() {
    window.location.reload();
  }
}
