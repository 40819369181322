<div *ngIf="showDialog" class="modal-wrapper">
  <kendo-dialog
    *ngIf="showDialog"
    #modal
    class="common-confirmation-modal"
    [title]="title ? title : defaultTitle"
    [width]="width"
    (close)="closeDialog()">
    <p *ngIf="warningText !== undefined" class="common-confirmation-modal-text-warning">
      {{ warningText }}
    </p>
    <p *ngIf="showConditionalText === true && conditionalText !== undefined" class="common-confirmation-modal-text">
      {{ conditionalText }}
    </p>
    <p class="common-confirmation-modal-text">
      {{ bodyText ? bodyText : defaultBodyText }}
    </p>
    <p *ngIf="additionalBodyText !== undefined" onclick="linkTextFunction" class="common-confirmation-modal-text">
      {{ additionalBodyText }}
    </p>
    <p *ngIf="linkText !== undefined" class="common-confirmation-modal-text">
      <CDMS-common-button-control
        [title]="linkText"
        [button]="'text'"
        [size]="'medium'"
        [type]="'primary'"
        (clickEvent)="linkFunction()">
      </CDMS-common-button-control>
    </p>
    <div class="btm-btn-bar">
      <kendo-dialog-actions>
        <CDMS-common-button-control
          #denyButton
          [show]="showDenyButton"
          [title]="denyText ? denyText : defaultDenyText"
          id="denyBtnCommonConfirmationModal"
          [button]="'standard'"
          [size]="'medium'"
          [type]="'secondary'"
          (clickEvent)="denyDialog()">
        </CDMS-common-button-control>
        <CDMS-common-button-control
          #affirmButton
          [show]="showDenyButton"
          [title]="affirmText ? affirmText : defaultAffirmText"
          id="affirmBtnCommonConfirmationModal"
          [button]="'standard'"
          [size]="'medium'"
          [type]="'primary'"
          (clickEvent)="affirmDialog()">
        </CDMS-common-button-control>
      </kendo-dialog-actions>
    </div>
  </kendo-dialog>
</div>
