<div class="page-wrapper">
  <div>
    <!-- FLOATING BUTTONS -->
    <div *ngIf="roleButton.isUser && !roleButton.isAdmin">
      <CDMS-common-button-control
        title="{{ 'title_Back' | langchange }}"
        [button]="'standard'"
        [size]="'small'"
        [type]="'primary'"
        [float]="true"
        [positionMode]="'absolute'"
        [align]="{ vertical: 'top', horizontal: 'end' }"
        [offset]="{ x: '50px', y: '100px' }"
        (clickEvent)="goBack()">
      </CDMS-common-button-control>
    </div>
    <div *ngIf="roleButton.isAttendant">
      <div class="column">
        <div *ngIf="attendantData.status" class="profile-status page-subtitle-2">
          <CDMS-status-indicator-control label="{{ 'title_Profile' | langchange }}" [status]="attendantData?.status">
          </CDMS-status-indicator-control>
        </div>
        <CDMS-common-button-control
          title="{{ 'title_Edit' | langchange }}"
          [button]="'standard'"
          [size]="'medium'"
          [type]="'primary'"
          [float]="true"
          [positionMode]="'absolute'"
          [align]="{ vertical: 'top', horizontal: 'end' }"
          [offset]="{ x: '50px', y: '100px' }"
          (clickEvent)="goEdit()">
        </CDMS-common-button-control>
      </div>
    </div>
    <div *ngIf="roleButton.isAdmin">
      <div class="status-box">
        <CDMS-status-container-control [statusData]="statusSelections" (clickEvent)="setStatus($event)">
        </CDMS-status-container-control>
      </div>
    </div>

    <div class="row">
      <!-- PROFILE PICTURE COLUMN -->
      <div class="column picture-column">
        <div class="page-subsection column max-width-30">
          <p class="page-subtitle-1">{{ attendantData?.firstName }} {{ attendantData?.lastName?.charAt(0) }}</p>
          <p *ngIf="attendantData?.snippet !== undefined" class="page-text textbox-small">
            {{ attendantData?.snippet }}
          </p>
          <div *ngIf="roleButton.isAdmin && statusSnippet">
            <CDMS-status-indicator-control
              label="{{ 'title_PersonalSummary' | langchange }}"
              [status]="statusSnippet.status">
            </CDMS-status-indicator-control>
          </div>
        </div>
        <div class="page-subsection column">
          <img
            class="profile-picture"
            title="{{ 'title_ProfilePicture' | langchange }}"
            [src]="attendantData?.photoUrl === '' ? '/assets/images/default-avatar.png' : attendantData?.photoUrl"
            alt="profile-picture" />
          <div *ngIf="roleButton.isAdmin && statusPic">
            <CDMS-status-indicator-control
              label="{{ 'title_ProfilePicture' | langchange }}"
              [status]="statusPic.status">
            </CDMS-status-indicator-control>
          </div>
        </div>
        <div class="page-subsection column">
          <div class="row">
            <p class="page-subtitle-2">{{ 'title_Contact' | langchange }}</p>
            <CDMS-common-info-slideout-control
              text="{{ 'page_Attendent_ContactInfo' | langchange }}"
              [position]="'right'"
              [iconSize]="'medium'"
              [slideoutSize]="'medium'">
            </CDMS-common-info-slideout-control>
          </div>
          <div>
            <CDMS-common-button-control
              title="{{ 'title_SENDMESSAGE' | langchange }}"
              [button]="'text'"
              [size]="'medium'"
              [type]="'primary'"
              (clickEvent)="showMessageModal()">
            </CDMS-common-button-control>
          </div>
        </div>
        <div *ngIf="attendantData?.languages.length > 0" class="page-subsection column">
          <p class="page-subtitle-2">{{ 'title_Languages' | langchange }}</p>
          <p
            *ngFor="let lang of attendantData?.languages | langTransform | isDeletedFilter; let indexOfelement = index"
            class="page-text-small m-l-20">
            {{ lang.name }}{{ indexOfelement !== attendantData?.languages.length - 1 ? ',' : null }}
          </p>
        </div>
      </div>

      <!-- PROFILE DETAILS COLUMN -->
      <div class="column details-column">
        <div class="page-subsection column">
          <p class="page-title">{{ 'page_Attendent_Hello' | langchange }},</p>
          <p class="page-subtitle-1">{{ 'page_Attendent_ABit' | langchange }}</p>
          <p *ngIf="attendantData?.about !== undefined" class="page-text textbox-large">{{ attendantData?.about }}</p>
          <div *ngIf="roleButton.isAdmin && statusAboutMe">
            <CDMS-status-indicator-control
              label="{{ 'page_Attendent_MoreAbout' | langchange }}"
              [status]="statusAboutMe.status">
            </CDMS-status-indicator-control>
          </div>
        </div>
        <div class="page-subsection column">
          <p class="page-subtitle-1">{{ 'title_Location' | langchange }}</p>
          <div class="column">
            <div *ngIf="attendantData?.travelDistance !== undefined" class="row m-t-10">
              <p class="page-text">{{ 'page_Attendent_Maximum' | langchange }}:&nbsp;</p>
              <p class="page-text">{{ attendantData?.travelDistance }}&nbsp;</p>
              <p class="page-text">{{ 'title_Miles' | langchange }}</p>
            </div>
          </div>
        </div>
        <div class="page-subsection column">
          <p class="page-subtitle-1">{{ 'title_Employment' | langchange }}</p>
          <div class="column">
            <div *ngIf="attendantData?.experience !== undefined" class="row m-t-10">
              <p class="page-text">{{ 'title_Experience' | langchange }}:&nbsp;</p>
              <p class="page-text">{{ attendantData?.experience }}&nbsp;</p>
              <p class="page-text">{{ 'title_Years' | langchange }}</p>
            </div>
            <div *ngIf="attendantData?.schedules && attendantData?.schedules.length > 0">
              <p class="page-text">{{ 'page_Attendent_PreferredWorkTimes' | langchange }}</p>
              <div>
                <CDMS-weekly-work-schedule-control [schedule]="attendantData.schedules" [readOnly]="true">
                </CDMS-weekly-work-schedule-control>
              </div>
            </div>
            <div *ngIf="attendantData?.weeklyHours !== undefined" class="row m-t-20">
              <p class="page-text">{{ 'title_TotalWeeklyWork' | langchange }}:&nbsp;</p>
              <p class="page-text">{{ attendantData?.weeklyHours }}&nbsp;</p>
              <p class="page-text">{{ 'title_Hours' | langchange }}</p>
            </div>
            <div *ngIf="attendantData?.workPreferences.length > 0" class="m-t-10">
              <p class="page-text">{{ 'page_Attendent_WorkEnvironmentPref' | langchange }}:</p>
              <p
                *ngFor="
                  let pref of attendantData?.workPreferences | langTransform | isDeletedFilter;
                  let indexOfelement = index
                "
                class="page-text-small m-l-20">
                {{ pref.name }}{{ indexOfelement !== attendantData?.workPreferences.length - 1 ? ',' : null }}
              </p>
            </div>
          </div>
        </div>
        <div *ngIf="attendantData?.services" class="page-subsection column">
          <p class="page-subtitle-1">{{ 'page_Attendent_ServicesProvided' | langchange }}</p>
          <div class="column">
            <p
              *ngFor="
                let service of attendantData?.services | langTransform | isDeletedFilter;
                let indexOfelement = index
              "
              class="page-text-small m-l-20">
              {{ service.name }}{{ indexOfelement !== attendantData?.services.length - 1 ? ',' : null }}
            </p>
          </div>
        </div>
        <div *ngIf="attendantData?.certifications" class="page-subsection column">
          <p class="page-subtitle-1">{{ 'title_Certifications' | langchange }}</p>
          <div class="column">
            <p
              *ngFor="
                let cert of attendantData?.certifications | langTransform | isDeletedFilter;
                let indexOfelement = index
              "
              class="page-text-small m-l-20">
              {{ cert.name }}{{ indexOfelement !== attendantData?.certifications.length - 1 ? ',' : null }}
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- CONTACT MESSAGE DIALOG BOX -->
<div class="dialog-wrapper">
  <kendo-dialog
    *ngIf="showMessageDialog"
    class="common-confirmation-modal"
    title="{{ 'title_Contact' | langchange }}"
    (close)="closeMessageModal('no')">
    <div class="page-subsection column-center">
      <form [formGroup]="contactForm">
        <div class="column">
          <div class="m-b-20 m-l-20 m-r-20">
            <p class="page-text">
              {{ 'page_Attendent_EnterYourEmail' | langchange }}
            </p>
          </div>
          <div class="row m-a">
            <kendo-formfield>
              <CDMS-common-input-control
                title="{{ 'title_Message' | langchange }}"
                [showCounter]="true"
                [maxLength]="500"
                [control]="$any(contactForm.get('message'))"
                ngDefaultControl
                formControlName="message"
                [input]="'textarea'"
                [size]="'medium'"
                [value]="contactForm.controls['message'].value">
              </CDMS-common-input-control>
            </kendo-formfield>
          </div>
          <div class="row m-a">
            <kendo-formfield>
              <CDMS-common-input-control
                title="{{ 'title_Email' | langchange }}"
                [control]="$any(this.contactForm.get('email'))"
                ngDefaultControl
                formControlName="email"
                [input]="'text'"
                [size]="'medium'"
                [value]="contactData.email">
              </CDMS-common-input-control>
            </kendo-formfield>
          </div>
        </div>
        <div>
          <kendo-dialog-actions>
            <CDMS-common-button-control
              title="{{ 'title_Cancel' | langchange }}"
              [button]="'standard'"
              [size]="'medium'"
              [type]="'secondary'"
              (clickEvent)="closeMessageModal('no')">
            </CDMS-common-button-control>
            <CDMS-common-button-control
              title="{{ 'title_SendMessage' | langchange }}"
              [button]="'standard'"
              [size]="'medium'"
              [type]="'primary'"
              [disabled]="contactForm.invalid"
              (clickEvent)="closeMessageModal('yes')">
            </CDMS-common-button-control>
          </kendo-dialog-actions>
        </div>
      </form>
    </div>
  </kendo-dialog>
</div>

<!-- MESSAGE SENT DIALOG BOX -->
<div class="dialog-wrapper">
  <kendo-dialog
    *ngIf="showMessageSuccessDialog"
    class="common-confirmation-modal"
    title="{{ 'page_Attendent_MessageSent_title' | langchange }}"
    (close)="closeMessageSuccessModal('no')">
    <div class="page-subsection column-center">
      <div class="column">
        <div class="m-b-20 m-l-20 m-r-20">
          <p class="page-text">
            {{ 'page_Attendent_MessageSent_p1' | langchange }}
          </p>
        </div>
      </div>
    </div>
  </kendo-dialog>
</div>
