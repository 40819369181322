<div class="page-wrapper">
  <ng-container *ngIf="!colorado">
    <div class="column-center">
      <p class="page-title m-t-20">{{ 'page_Register_Title' | langchange }}</p>
      <div>
        <!-- Easy Steps to Register -->
        <p class="page-subtitle-1 m-t-5vh">{{ 'page_Register_EasyStepsTitle' | langchange }}</p>
        <ul>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p1' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p2' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p3' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p4' | langchange }}</li>
        </ul>
        <p class="page-text m-t-40 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p5' | langchange }}</p>
        <!-- Terms and Conditions -->
        <p class="page-subtitle-1 m-t-10vh">{{ 'page_Home_Terms_title' | langchange }}</p>
        <div class="m-t-10 column-center">
          <form class="m-l-40 flex-center-col" [formGroup]="termsForm">
            <p class="page-text textbox-medium">{{ 'page_Home_Terms' | langchange }}</p>
            <div class="row m-t-10">
              <CDMS-common-button-control
                title="{{ 'page_Home_Terms_view' | langchange }}"
                [button]="'text'"
                [size]="'medium'"
                [type]="'primary'"
                class="m-r-40"
                (clickEvent)="showFullTerms()">
              </CDMS-common-button-control>
              <CDMS-common-input-control
                title="{{ 'title_Agree' | langchange }}"
                [control]="$any(this.termsForm?.get('agree'))"
                ngDefaultControl
                formControlName="agree"
                [input]="'checkbox'"
                [size]="'xsmall'"
                [value]="termsData.agree">
              </CDMS-common-input-control>
            </div>
            <div class="row">
              <CDMS-common-button-control
                title="{{ 'title_Back' | langchange }}"
                [button]="'standard'"
                [size]="'medium'"
                [type]="'secondary'"
                class="m-r-10"
                (clickEvent)="goBack()">
              </CDMS-common-button-control>
              <CDMS-common-button-control
                title="{{ 'title_Continue' | langchange }}"
                [button]="'standard'"
                [size]="'medium'"
                [type]="'primary'"
                [disabled]="!termsForm.controls['agree'].value"
                (clickEvent)="goSignup()">
              </CDMS-common-button-control>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="colorado">
    <div class="column-center">
      <p class="page-title m-t-30">{{ 'page_Register_Title_CO' | langchange }}</p>
      <div>
        <!-- Easy Steps to Register -->
        <p class="page-subtitle-1 m-t-5vh">{{ 'page_Register_EasyStepsTitle_CO' | langchange }}</p>
        <ul>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p1_CO' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p2_CO' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p3_CO' | langchange }}</li>
          <li class="page-text m-t-10 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p4_CO' | langchange }}</li>
        </ul>
        <p class="page-text m-t-40 m-l-40 textbox-large">{{ 'page_Register_EasySteps_p5_CO' | langchange }}</p>
        <!-- Terms and Conditions -->
        <p class="page-subtitle-1 m-t-10vh">{{ 'page_Home_Terms_title' | langchange }}</p>
        <div class="m-t-10 column-center">
          <form class="m-l-40 flex-center-col" [formGroup]="termsForm">
            <p class="page-text textbox-medium">{{ 'page_Home_Terms' | langchange }}</p>
            <div class="row m-t-10">
              <CDMS-common-button-control
                title="{{ 'page_Home_Terms_view' | langchange }}"
                [button]="'text'"
                [size]="'medium'"
                [type]="'primary'"
                class="m-r-40"
                (clickEvent)="showFullTerms()">
              </CDMS-common-button-control>
              <CDMS-common-input-control
                title="{{ 'title_Agree' | langchange }}"
                [control]="$any(this.termsForm?.get('agree'))"
                ngDefaultControl
                formControlName="agree"
                [input]="'checkbox'"
                [size]="'xsmall'"
                [value]="termsData.agree">
              </CDMS-common-input-control>
            </div>
            <div class="row">
              <CDMS-common-button-control
                title="{{ 'title_Back' | langchange }}"
                [button]="'standard'"
                [size]="'medium'"
                [type]="'secondary'"
                class="m-r-10"
                (clickEvent)="goBack()">
              </CDMS-common-button-control>
              <CDMS-common-button-control
                title="{{ 'title_Continue' | langchange }}"
                [button]="'standard'"
                [size]="'medium'"
                [type]="'primary'"
                [disabled]="!termsForm.controls['agree'].value"
                (clickEvent)="goSignup()">
              </CDMS-common-button-control>
            </div>
          </form>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<!-- FULL TERMS AND CONDITIONS-->
<div *ngIf="showFullTermsModal" class="modal-wrapper">
  <kendo-dialog
    *ngIf="showFullTermsModal"
    class="full-terms-modal"
    [title]="'page_Home_Terms_title' | langchange"
    (close)="closeFullTermsModal()">
    <div>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_1' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_2' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_3' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_4' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_5' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_6' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_7' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_8' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_9' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_10' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_11' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_12' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_13' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_14' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_15' | langchange }}</p>
      <p class="page-text text-bold textbox-large m-t-10">{{ 'page_Home_TC_16' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_17' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_18' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_19' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_20' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_21' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_22' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_23' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_24' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_25' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_26' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">{{ 'page_Home_TC_27' | langchange }}</p>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_28' | langchange }}</p>
      <div class="row">
        <p class="page-text textbox-large m-t-10 m-r-10">{{ 'page_Home_TC_29' | langchange }}</p>
        <a href="mailto:privacy@consumerdirectonline.com" class="page-text textbox-large m-t-10">
          privacy&#64;consumerdirectonline.com</a
        >
      </div>
      <p class="page-subtitle-1 m-t-10">{{ 'page_Home_TC_30' | langchange }}</p>
      <p class="page-text textbox-large m-t-10">11/26/2013</p>
    </div>
  </kendo-dialog>
</div>

<CDMS-common-confirmation-modal-control
  [modalType]="'confirm'"
  [showDialog]="showLogoutModal"
  [title]="'title_SignOut' | langchange"
  [bodyText]="'page_Register_AlreadySignedIn' | langchange"
  [affirmText]="'title_SignOut' | langchange"
  [denyText]="'title_Cancel' | langchange"
  (affirmEvent)="closeChangesModal('yes')"
  (denyEvent)="closeChangesModal('no')"
  (closeEvent)="closeChangesModal('no')">
</CDMS-common-confirmation-modal-control>
