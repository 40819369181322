<div *ngIf="show" class="upload-wrapper">
  <kendo-upload
    #upload
    class="upload-{{ type }} upload-{{ type }}-{{ lang }}"
    [autoUpload]="autoUpload"
    [multiple]="false"
    [restrictions]="fileRestrictions"
    [disabled]="disabled"
    (upload)="onUpload($event)"
    (cancel)="onCancel()"
    (clear)="onClear()"
    (complete)="onComplete()"
    (error)="onError()"
    (success)="onSuccess()">
  </kendo-upload>
</div>
