import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AppConstants } from 'src/app/constants/app-constants';

import { ISchedule } from '../../constants/app-interfaces';
import { Schedule } from '../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-weekly-work-schedule-control',
  templateUrl: './weekly-work-schedule-control.component.html',
  styleUrls: ['./weekly-work-schedule-control.component.scss'],
})
export class WeeklyWorkScheduleControlComponent implements OnInit {
  @Input()
  public set schedule(data: Schedule[]) {
    this._schedule = [];
    data?.forEach(x => {
      const entry: ISchedule = {
        day: x.dayOfWeek,
        selected: true,
        time: x.id % 2 === 1 ? 1 : 0,
      };
      this._schedule.push(entry);
    });
    this.createSchedule();
  }

  @Input() show = true;
  @Input() readOnly = false;

  @Output() valueChange = new EventEmitter();

  private _schedule: ISchedule[];
  private lang;

  constructor() {}

  ngOnInit() {
    this.createSchedule();
    this.lang = AppConstants.selectedLanguage;
  }

  private createSchedule() {
    if (this._schedule === undefined) {
      this._schedule = [];
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 2; j++) {
          this._schedule.push({
            day: i,
            time: j,
            selected: false,
          });
        }
      }
    } else {
      for (let i = 0; i < 7; i++) {
        for (let j = 0; j < 2; j++) {
          // Not very effecient, find better way
          if (!this._schedule.find(x => x.day === i && x.time === j)) {
            this._schedule.push({
              day: i,
              time: j,
              selected: false,
            });
          }
        }
      }
    }
  }

  public set(time: number, day: number) {
    if (!this.readOnly) {
      const entry = this._schedule.find(x => x.day === day && x.time === time);
      entry.selected = !entry.selected;
      this.emitSelected();
    }
  }

  public getSelected(time: number, day: number) {
    return this._schedule.find(x => x.day === day && x.time === time).selected;
  }

  public emitAll() {
    if (!this.readOnly) {
      this.valueChange.emit(this.schedule);
    }
  }

  public emitSelected() {
    if (!this.readOnly) {
      const selectedSchedule: Schedule[] = [];
      this._schedule.forEach(x => {
        if (x.selected) {
          const newSchedule = new Schedule();
          newSchedule.dayOfWeek = x.day;
          newSchedule.id = this.getId(x.day, x.time);
          selectedSchedule.push(newSchedule);
        }
      });
      this.valueChange.emit(selectedSchedule);
    }
  }

  public getId(day: number, time: number): number {
    let id = 0;
    switch (day) {
      case 0:
        id = time === 0 ? 42 : 43;
        break;
      case 1:
        id = time === 0 ? 30 : 31;
        break;
      case 2:
        id = time === 0 ? 32 : 33;
        break;
      case 3:
        id = time === 0 ? 34 : 35;
        break;
      case 4:
        id = time === 0 ? 36 : 37;
        break;
      case 5:
        id = time === 0 ? 38 : 39;
        break;
      case 6:
        id = time === 0 ? 40 : 41;
        break;
    }
    return id;
  }

  public displayDay(dayNum: number) {
    const isEnglish = this.lang === 'en';
    switch (dayNum) {
      case 0:
        return isEnglish ? 'Sun' : 'Dom';
      case 1:
        return isEnglish ? 'Mon' : 'Lun';
      case 2:
        return isEnglish ? 'Tue' : 'Mar';
      case 3:
        return isEnglish ? 'Wed' : 'Mié';
      case 4:
        return isEnglish ? 'Thu' : 'Jue';
      case 5:
        return isEnglish ? 'Fri' : 'Vie';
      case 6:
        return isEnglish ? 'Sat' : 'Sab';
    }
  }

  public displayTime(timeNum: number) {
    const isEnglish = this.lang === 'en';
    switch (timeNum) {
      case 0:
        return isEnglish ? 'AM' : 'de la mañana';
      case 1:
        return isEnglish ? 'PM' : 'de la tarde';
    }
  }
}
