import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { b2cPolicies } from 'src/app/auth-config';

import MsalServiceWrapper from '../../../services/msal.wrapper.service';

@Component({
  selector: 'attendant-affirm',
  templateUrl: 'attendant-affirm.page.html',
  styleUrls: ['attendant-affirm.page.scss'],
})
export class AttendantAffirmPage implements OnInit {
  constructor(
    private activatedRoute: ActivatedRoute,
    private msalService: MsalServiceWrapper
  ) {}

  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(urlParams => {
      // Redirect to SignInSignUp -> PasswordReset flow for migrated users' inital password set
      this.msalService.redirect(b2cPolicies.authorities.signUpSignIn.authority, urlParams);
    });
  }
}
