import { ILocale, IPageDetails } from './app-interfaces';
import { DaysAbbr } from './app-types';

export class AppConstants {
  public static defaultLanguage = 'en';
  public static selectedLanguage = 'en';
  public static preferredLanguageData = {};
  public static isEsPreferredLang = false;
}

export const locales: ILocale[] = [
  {
    id: 1,
    code: 'en',
    text: 'English',
    u2LangCode: 'Eng',
  },
  {
    id: 2,
    code: 'es',
    text: 'Español',
    u2LangCode: 'Esp',
  },
];
export const PageDetailArray: IPageDetails[] = [
  {
    page: 'home',
    pageTitle: 'Home',
    pageHelp:
      'Begin your search for at home care using the provider search or start the process of providing care yourself.',
  },
  {
    page: 'admin-attendants',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'admin-accounts',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'search',
    pageTitle: 'Search Providers',
    pageHelp:
      'Use the search filters to find provider and view a preview of their profile.' +
      '  Select an provider card to view their full profile and get in touch.',
  },
  {
    page: 'attendant',
    pageTitle: 'Provider Profile',
    pageHelp:
      'Select the Contact button and fill out a message for the provider.' +
      "  Don't forget to add your email so the provider can respond if they believe they are a good match.",
  },
  {
    page: 'attendant-edit',
    pageTitle: 'Edit Profile',
    pageHelp:
      'Edit your profile and so that those seeking care can better find you.' +
      ' All fields with a star at the end of the title must be filled out to save.',
  },
  {
    page: 'login',
    pageTitle: 'Login',
    pageHelp: 'Login to your account.',
  },
  {
    page: 'registration',
    pageTitle: 'Registration',
    pageHelp: 'Register to become an attendant and begin getting in contact with those seeking care.',
  },
  {
    page: 'requirements',
    pageTitle: 'Requirements',
    pageHelp: 'Read all requirements to see if you can become an provider.',
  },
];

export const PageDetailArrayES: IPageDetails[] = [
  {
    page: 'home',
    pageTitle: 'Página de Inicio',
    pageHelp:
      'Comience su búsqueda de atención domiciliaria utilizando la búsqueda ' +
      'de asistente o inicie el proceso de brindar atención usted mismo.',
  },
  {
    page: 'admin-attendants',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'admin-accounts',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'search',
    pageTitle: 'Proveedores de Búsqueda',
    pageHelp:
      'Utilice los filtros de búsqueda para encontrar asistentes y ver una vista previa de su perfil.' +
      ' Seleccione una tarjeta de asistente para ver su perfil completo y ponerse en contacto.',
  },
  {
    page: 'attendant',
    pageTitle: 'Perfil del Proveedor',
    pageHelp:
      'Seleccione el botón Contacto y complete un mensaje para el asistente. ' +
      'No olvide agregar su correo electrónico para que el asistente pueda responder si cree que es una buena pareja.',
  },
  {
    page: 'attendant-edit',
    pageTitle: 'Editar Perfil',
    pageHelp:
      'Edite su perfil y para que aquellos que buscan atención puedan encontrarlo mejor.' +
      ' Todos los campos con una estrella al final del título deben completarse para guardar.',
  },
  {
    page: 'login',
    pageTitle: 'Acceso',
    pageHelp: 'Ingrese a su cuenta.',
  },
  {
    page: 'registration',
    pageTitle: 'Registro',
    pageHelp:
      'Regístrese para convertirse en un asistente y comience a ponerse en contacto con quienes buscan atención.',
  },
  {
    page: 'requirements',
    pageTitle: 'Requisitos',
    pageHelp: 'Lea todos los requisitos para ver si puede convertirse en un asistente.',
  },
];

export const PageDetailArrayCO: IPageDetails[] = [
  {
    page: 'home',
    pageTitle: 'Home',
    pageHelp:
      'Begin your search for at home care using the attendant search or start the process of providing care yourself.',
  },
  {
    page: 'admin-attendants',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'admin-accounts',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'search',
    pageTitle: 'Search Attendants',
    pageHelp:
      'Use the search filters to find attendants and view a preview of their profile.' +
      '  Select an attendant card to view their full profile and get in touch.',
  },
  {
    page: 'attendant',
    pageTitle: 'Attendant Profile',
    pageHelp:
      'Select the Contact button and fill out a message for the attendant.' +
      "  Don't forget to add your email so the attendant can respond if they believe they are a good match.",
  },
  {
    page: 'attendant-edit',
    pageTitle: 'Edit Profile',
    pageHelp:
      'Edit your profile and so that those seeking care can better find you.' +
      ' All fields with a star at the end of the title must be filled out to save.',
  },
  {
    page: 'login',
    pageTitle: 'Login',
    pageHelp: 'Login to your account.',
  },
  {
    page: 'registration',
    pageTitle: 'Registration',
    pageHelp: 'Register to become an attendant and begin getting in contact with those seeking care.',
  },
  {
    page: 'requirements',
    pageTitle: 'Requirements',
    pageHelp: 'Read all requirements to see if you can become an attendant.',
  },
];

export const PageDetailArrayCOES: IPageDetails[] = [
  {
    page: 'home',
    pageTitle: 'Página de Inicio',
    pageHelp:
      'Comience su búsqueda de atención domiciliaria utilizando la búsqueda ' +
      'de asistente o inicie el proceso de brindar atención usted mismo.',
  },
  {
    page: 'admin-attendants',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'admin-accounts',
    pageTitle: '',
    pageHelp: '',
  },
  {
    page: 'search',
    pageTitle: 'Asistentes de Búsqueda',
    pageHelp:
      'Utilice los filtros de búsqueda para encontrar asistentes y ver una vista previa de su perfil.' +
      ' Seleccione una tarjeta de asistente para ver su perfil completo y ponerse en contacto.',
  },
  {
    page: 'attendant',
    pageTitle: 'Perfil del Asistente',
    pageHelp:
      'Seleccione el botón Contacto y complete un mensaje para el asistente. ' +
      'No olvide agregar su correo electrónico para que el asistente pueda responder si cree que es una buena pareja.',
  },
  {
    page: 'attendant-edit',
    pageTitle: 'Editar Perfil',
    pageHelp:
      'Edite su perfil y para que aquellos que buscan atención puedan encontrarlo mejor.' +
      ' Todos los campos con una estrella al final del título deben completarse para guardar.',
  },
  {
    page: 'login',
    pageTitle: 'Acceso',
    pageHelp: 'Ingrese a su cuenta.',
  },
  {
    page: 'registration',
    pageTitle: 'Registro',
    pageHelp:
      'Regístrese para convertirse en un asistente y comience a ponerse en contacto con quienes buscan atención.',
  },
  {
    page: 'requirements',
    pageTitle: 'Requisitos',
    pageHelp: 'Lea todos los requisitos para ver si puede convertirse en un asistente.',
  },
];

export const radiusOptions: Array<number> = [5, 10, 25, 50, 80];

export const profileStatusOptions: Array<{ text: string; value: number }> = [
  { text: 'All', value: null },
  { text: 'New', value: 0 },
  { text: 'Pending', value: 7 },
  { text: 'Approved', value: 1 },
  { text: 'Rejected', value: 2 },
  { text: 'Deleted', value: 3 },
  { text: 'Spam', value: 4 },
  { text: 'Archived', value: 5 },
  { text: 'Reviewed', value: 6 },
];

export const choiceOptions: Array<{ text: string; value: boolean }> = [
  { text: 'Active', value: true },
  { text: 'Inactive', value: false },
];

export const dateOptions: string[] = ['Today', 'One Week', 'Two Weeks'];

export const optionsDaysAbbr: DaysAbbr[] = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export const optionsDaysAbbrES: DaysAbbr[] = ['Dom', 'Lun', 'Mar', 'Mié', 'Jue', 'Vie', 'Sab'];

export enum Status {
  'New' = 0,
  'Approved' = 1,
  'Rejected' = 2,
  'Deleted' = 3,
  'Spam' = 4,
  'Archived' = 5,
  'Reviewed' = 6,
  'Pending' = 7,
}

export enum StatusSpanish {
  'Nuevo' = 0,
  'Aprobado' = 1,
  'Rechazado' = 2,
  'Eliminado' = 3,
  'Spam' = 4,
  'Archivado' = 5,
  'Revisados' = 6,
  'Pendiente' = 7,
}

export enum RequestType {
  'Photo' = 0,
  'Resume' = 1,
  'AboutMe' = 2,
  'Snippet' = 3,
}
