import { Injectable } from '@angular/core';
import enLangJson from 'src/assets/i18n/resources-locale_en.json';
import esLangJson from 'src/assets/i18n/resources-locale_es.json';

import { AppConstants } from '../../app/constants/app-constants';

@Injectable({
  providedIn: 'root',
})
export class LanguageChangeService {
  constructor() {}

  public use(lang: string): Promise<any> {
    AppConstants.preferredLanguageData = {};
    AppConstants.selectedLanguage = lang;
    switch (lang) {
      case 'en':
        AppConstants.preferredLanguageData = enLangJson;
        break;
      case 'es':
        AppConstants.preferredLanguageData = esLangJson;
        break;
      default:
        AppConstants.preferredLanguageData = enLangJson;
        break;
    }
    return Promise.resolve(AppConstants.preferredLanguageData);
  }
}
