import { Injectable, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { MsalBroadcastService, MsalService } from '@azure/msal-angular';
import { EventMessage, EventType, InteractionStatus } from '@azure/msal-browser';
import { BehaviorSubject, Observable, Subject, filter, single, take, takeUntil } from 'rxjs';
import { silentRequest } from 'src/app/auth-config';
import {
  INotification,
  NotificationSystemService,
} from 'src/app/common-components/controllers/common-notification-system.controller';
import { AppbarService } from 'src/app/components/appbar/appbar-service';
import { AppConstants } from 'src/app/constants/app-constants';
import { Roles } from 'src/app/constants/app-types';
import {
  AttendantRequest,
  AttendantResponseModel,
  ProviderDirectoryFunctionService,
} from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';
import { b2c_config } from 'src/environments/environment';

import { AttendantProfileService } from '../attendant/attendant-profile.controller';
import { CertificatesService } from '../reference-data/certificates.controller';
import { LanguagesService } from '../reference-data/languages.controller';
import { ServicesService } from '../reference-data/services.controller';
import { WorkPreferencesService } from '../reference-data/work-preferences.controller';

@Injectable({
  providedIn: 'root',
})
export class UserService implements OnDestroy {
  private _token: BehaviorSubject<any> = new BehaviorSubject<any>(undefined);
  private _id: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private _email: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private role: BehaviorSubject<Roles[]> = new BehaviorSubject<Roles[]>(['User']);
  private roleHighest: BehaviorSubject<Roles> = new BehaviorSubject<Roles>('User');
  private _entity: BehaviorSubject<object> = new BehaviorSubject<object>(undefined);
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private newEmail: string;
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private router: Router,
    private notificationSystemService: NotificationSystemService,
    private authService: MsalService,
    private appbarService: AppbarService,
    private msalBroadcastService: MsalBroadcastService,
    private attendantProfileService: AttendantProfileService,
    private providerDirectoryFunctionService: ProviderDirectoryFunctionService,
    private servicesService: ServicesService,
    private certificatesService: CertificatesService,
    private workPreferencesService: WorkPreferencesService,
    private languagesService: LanguagesService
  ) {
    this.msalBroadcastService.inProgress$
      .pipe(
        filter((status: InteractionStatus) => status === InteractionStatus.None),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (status: InteractionStatus) => {
          this.getTokenInfo();
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {},
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result: EventMessage) => {
          this.servicesService.fetchData();
          this.certificatesService.fetchData();
          this.workPreferencesService.fetchData();
          this.languagesService.fetchData();
          this._token.next(result.payload);
          this._id.next(result.payload['idTokenClaims'].sub);
          this.updateRole(result.payload['idTokenClaims'].role);
          if (this.newEmail === undefined) {
            this._email.next(result.payload['idTokenClaims'].email);
            this.newEmail = result.payload['idTokenClaims'].email;
            this.checkAccount();
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {},
      });

    this.msalBroadcastService.msalSubject$
      .pipe(
        filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS),
        takeUntil(this._destroying$)
      )
      .subscribe({
        next: (result: EventMessage) => {
          switch (result.payload['idTokenClaims']?.acr) {
            case b2c_config.change_signin_name_policy.toLowerCase():
              if (this.newEmail === undefined) {
                this._id.next(result.payload['idTokenClaims'].sub);
                this._email.next(result.payload['idTokenClaims'].email);
                this.newEmail = result.payload['idTokenClaims'].email;
                this.checkAccount();
              }
              break;
            case b2c_config.password_reset_policy.toLowerCase():
              const txt =
                AppConstants.selectedLanguage === 'en'
                  ? 'Password Updated Successfully'
                  : 'Contraseña Actualizado con Exito';
              const notification: INotification = { type: 'success', content: txt };
              this.notificationSystemService.setNotification(notification);
              this.router.navigate(['/Attendant/Edit']);
              break;
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {},
      });
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public setLoad(data: boolean) {
    this._load.next(data);
  }

  public getId(): Observable<string> {
    return this._id;
  }

  public getIdNow(): string {
    return this._id.getValue();
  }

  public getEmail(): Observable<string> {
    return this._email;
  }

  public getEmailNow(): string {
    return this._email.getValue();
  }

  public getToken(): Observable<any> {
    return this._token;
  }

  public getTokenNow(): any {
    return this._token.getValue();
  }

  public getRoles(): Observable<Roles[]> {
    return this.role;
  }

  public getRoleHighest(): Observable<Roles> {
    return this.roleHighest;
  }

  public getRoleHighestNow(): Roles {
    return this.roleHighest.getValue();
  }

  private getTokenInfo() {
    silentRequest.account = this.authService.instance.getAllAccounts()[0];
    if (silentRequest.account !== undefined) {
      if (silentRequest.account.idTokenClaims !== undefined) {
        silentRequest.authority =
          `https://${b2c_config.authority}/${b2c_config.directory}/` + silentRequest.account.idTokenClaims.acr;
      }
      silentRequest.forceRefresh = true;
      this.authService
        .acquireTokenSilent(silentRequest)
        .pipe(single(), takeUntil(this._destroying$))
        .subscribe({
          next: data => {
            if (data !== undefined) {
              this._token.next(data);
              this._id.next(data.idTokenClaims['sub']);
              this.updateRole(data.idTokenClaims['role']);
            }
          },
          error: error => {
            if (error.message) {
              const notification: INotification = { type: 'error', content: error.message };
              this.notificationSystemService.setNotification(notification);
            }
          },
          complete: () => {
            this._load.next(false);
          },
        });
    }
  }

  public updateRole(data: Roles[]): void {
    if (data) {
      if (!Array.isArray(data)) {
        data = [data];
      }
      this.role.next(data);
      if (data.find(r => r === 'ProviderDirectoryAdmin')) {
        this.roleHighest.next('ProviderDirectoryAdmin');
        this.router.navigate(['/Admin/Attendants']);
      } else if (data.find(r => r === 'Attendant')) {
        this.roleHighest.next('Attendant');
        this.attendantProfileService.search(this._id.getValue());
      } else {
        this.roleHighest.next('User');
      }
      this.appbarService.setRole(this.roleHighest.getValue());
    }
  }

  private checkAccount() {
    this._load.next(true);
    this.providerDirectoryFunctionService
      .getAttendantById(this._id.getValue())
      .pipe(take(1), takeUntil(this._destroying$))
      .subscribe({
        next: data => {
          if (this.roleHighest.getValue() !== 'ProviderDirectoryAdmin' && data.data.userId === '') {
            this.createAccount();
          } else if (this.roleHighest.getValue() !== 'ProviderDirectoryAdmin' && data.data.email !== this.newEmail) {
            this.changeEmail();
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  private createAccount() {
    const request = new AttendantRequest();
    request.userId = this._id.getValue();
    request.email = this._email.getValue();
    this._load.next(true);
    this.providerDirectoryFunctionService
      .createAttendant(request)
      .pipe(take(1), takeUntil(this._destroying$))
      .subscribe({
        next: (res: AttendantResponseModel) => {
          if (res) {
            if (!res.isSuccess) {
              const notification: INotification = { type: 'error', content: res.message };
              this.notificationSystemService.setNotification(notification);
            } else {
              this._entity.next(res.data);
              this.attendantProfileService.setSearchParameters(request.userId);
              this.router.navigate(['/Attendant/Edit']);
            }
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  private changeEmail() {
    if (this._email.getValue()) {
      const request = new AttendantRequest();
      request.userId = this._id.getValue();
      request.email = this._email.getValue();
      request.emailUpdate = true;
      this._load.next(true);
      this.providerDirectoryFunctionService
        .updateAttendant(request)
        .pipe(take(1), takeUntil(this._destroying$))
        .subscribe({
          next: (res: AttendantResponseModel) => {
            if (res) {
              if (!res.isSuccess) {
                const notification: INotification = { type: 'error', content: res.message };
                this.notificationSystemService.setNotification(notification);
              } else {
                this._entity.next(res.data);
                const txt =
                  AppConstants.selectedLanguage === 'en'
                    ? 'Email Updated Successfully'
                    : 'Correo Electronico Actualizado con Exito';
                const notification: INotification = { type: 'success', content: txt };
                this.notificationSystemService.setNotification(notification);
                this.router.navigate(['/Attendant/Edit']);
              }
            }
          },
          error: error => {
            if (error.message) {
              const notification: INotification = { type: 'error', content: error.message };
              this.notificationSystemService.setNotification(notification);
            }
          },
          complete: () => {
            this.newEmail = undefined;
            this._load.next(false);
          },
        });
    } else {
      this.router.navigate(['/Attendant/Edit']);
    }
  }

  public clear(): void {
    this._entity.next(null);
  }

  public clearEntity(): void {
    this._entity.next(null);
  }

  public clearLoad(): void {
    this._load.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
