import { Component, Input } from '@angular/core';
import { LoaderSize, LoaderThemeColor, LoaderType } from '@progress/kendo-angular-indicators';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-common-load-indicator-control',
  templateUrl: './common-load-indicator-control.component.html',
  styleUrls: ['../assets/scss/_common-components.scss'],
})
export class CommonLoadIndicatorControl {
  @Input() show = false;
  @Input() type: LoaderType = 'converging-spinner';
  @Input() size: LoaderSize = 'medium';

  constructor() {}
}
