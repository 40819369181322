import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';

import { UserService } from '../controllers/user/user.controller';

@Injectable()
export class AdminAuthGuard {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const data = this.userService.getRoleHighestNow();
    if (data !== undefined) {
      if (data === 'ProviderDirectoryAdmin') {
        return true;
      } else {
        this.router.navigate(['/Home']);
        return false;
      }
    } else {
      return false;
    }
  }
}

@Injectable()
export class AttendantAuthGuard {
  constructor(
    private userService: UserService,
    private router: Router
  ) {}

  canActivate(): Observable<boolean> | Promise<boolean> | boolean {
    const data = this.userService.getRoleHighestNow();
    if (data !== undefined) {
      if (data === 'Attendant' || data === 'ProviderDirectoryAdmin') {
        return true;
      } else {
        this.router.navigate(['/Home']);
        return false;
      }
    } else {
      return false;
    }
  }
}
