import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

export interface INotification {
  content: string;
  type: 'success' | 'error' | 'info' | 'warning';
  close?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NotificationSystemService implements OnDestroy {
  private _message: BehaviorSubject<INotification> = new BehaviorSubject<INotification>(undefined);

  private readonly _destroying$ = new Subject<void>();

  constructor() {}

  public getNotification(): Observable<INotification> {
    return this._message;
  }

  public setNotification(data: INotification) {
    this._message.next(data);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
