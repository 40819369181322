import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import {
  INotification,
  NotificationSystemService,
} from 'src/app/common-components/controllers/common-notification-system.controller';

import {
  ProviderDirectoryFunctionService,
  ReviewRequest,
} from '../../services/providerdirectoryapi/providerdirectoryfunctionapi.services';

@Injectable({
  providedIn: 'root',
})
export class AdminApprovalService implements OnDestroy {
  private _response: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(undefined);
  private _parameters: BehaviorSubject<string> = new BehaviorSubject<string>(undefined);
  private _requests: BehaviorSubject<ReviewRequest[]> = new BehaviorSubject<ReviewRequest[]>(undefined);
  private _load: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private readonly _destroying$ = new Subject<void>();

  constructor(
    private providerDirectoryFunctionService: ProviderDirectoryFunctionService,
    private notificationSystemService: NotificationSystemService
  ) {}

  public getReviewRequest(data?: string): void {
    if (data !== undefined) {
      this._parameters.next(data);
    } else {
      data = this._parameters.getValue();
    }
    this._load.next(true);
    this.providerDirectoryFunctionService
      .getReviewRequestByUserId(data)
      .pipe(take(1), takeUntil(this._destroying$))
      .subscribe({
        next: res => {
          if (res) {
            if (!res.isSuccess) {
              const notification: INotification = { type: 'error', content: res.message };
              this.notificationSystemService.setNotification(notification);
            } else {
              this._requests.next(res.data);
            }
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  public createReviewRequest(data?: ReviewRequest[]): void {
    if (data !== undefined) {
      this._requests.next(data);
    } else {
      data = this._requests.getValue();
    }
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    this._load.next(true);
    this.providerDirectoryFunctionService
      .createReviewRequest(data)
      .pipe(take(1), takeUntil(this._destroying$))
      .subscribe({
        next: res => {
          if (res) {
            this._response.next(res);
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  public updateReviewRequest(data?: ReviewRequest[]): void {
    if (data !== undefined) {
      this._requests.next(data);
    } else {
      data = this._requests.getValue();
    }
    Object.keys(data).forEach(key => {
      if (data[key] === null) {
        delete data[key];
      }
    });
    this._load.next(true);
    this.providerDirectoryFunctionService
      .updateReviewRequest(data)
      .pipe(take(1), takeUntil(this._destroying$))
      .subscribe({
        next: res => {
          if (res) {
            this._response.next(res);
          }
        },
        error: error => {
          if (error.message) {
            const notification: INotification = { type: 'error', content: error.message };
            this.notificationSystemService.setNotification(notification);
          }
        },
        complete: () => {
          this._load.next(false);
        },
      });
  }

  public getParameters(): Observable<string> {
    return this._parameters;
  }

  public setParameters(data: string) {
    this._parameters.next(data);
  }

  public getRequestsByID(id: string | undefined, override = false): Observable<ReviewRequest[]> {
    if (override === true && id) {
      this.getReviewRequest(id);
    }
    return this._requests;
  }

  public setRequests(data: ReviewRequest[]) {
    this._requests.next(data);
  }

  public getResponse(): Observable<boolean> {
    return this._response;
  }

  public getLoad(): Observable<boolean> {
    return this._load;
  }

  public clear(): void {
    this._response.next(null);
    this._requests.next(null);
    this._parameters.next(null);
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
