import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { State } from '@progress/kendo-data-query';
import { searchIcon } from '@progress/kendo-svg-icons';
import { Subject, takeUntil } from 'rxjs';
import { CommonConstants } from 'src/app/common-components/constants/common-constants';
import {
  INotification,
  NotificationSystemService,
} from 'src/app/common-components/controllers/common-notification-system.controller';
import { ValidationFunctions } from 'src/app/common-components/utilities/common-validation-functions';
import { AdminAttendantsService } from 'src/app/controllers/admin/admin-attendants.controller';
import { AttendantProfileService } from 'src/app/controllers/attendant/attendant-profile.controller';
import { EmailService } from 'src/app/controllers/email/email.controller';
import {
  Attendant,
  EmailRequest,
  ICertification,
  IdEmailRequest,
} from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';

import { IKendoGridColumnSetting } from '../../../common-components/common-grid/IKendoGridColumnSetting';
import { KendoConstants } from '../../../common-components/constants/common-kendo-constants';
import { AppbarService } from '../../../components/appbar/appbar-service';
import { profileStatusOptions } from '../../../constants/app-constants';
import { CertificatesService } from '../../../controllers/reference-data/certificates.controller';
import { EmailTypesService } from '../../../controllers/reference-data/email-types.controller';
import { StateService } from '../../../controllers/reference-data/states.controller';

export interface IAdminAttendantSearch {
  status?: string;
  firstName?: string;
  lastName?: string;
  id?: string;
  email?: string;
  zipcode?: string;
  state?: string;
  certifications?: string;
}

@Component({
  selector: 'app-admin-attendants',
  templateUrl: 'admin-attendants.page.html',
  styleUrls: ['admin-attendants.page.scss'],
})
export class AdminAttendantsPage implements OnInit, OnDestroy {
  public searchResults: Attendant[];
  public searchData: IAdminAttendantSearch = {
    status: null,
    firstName: null,
    lastName: null,
    id: null,
    email: null,
    zipcode: null,
    state: null,
    certifications: null,
  };
  public searchForm: FormGroup;
  public emailForm: FormGroup;
  public emailData = {
    email: null,
  };
  public profileStatusOptions: Array<{ text: string; value: number }> = profileStatusOptions;
  public stateOptions;
  public certOptions: ICertification[];
  public emailOptions;

  public columns: Array<IKendoGridColumnSetting>;
  public gridLoading = false;
  public pageSize: number = KendoConstants.kendoMasterGridProperties.pageSize;
  public pageNumber: number = KendoConstants.kendoMasterGridProperties.pageNumber;
  public state: State = {
    skip: 0,
    take: 10,
    filter: { logic: 'or', filters: [] },
    sort: [{ field: 'approvalDate', dir: 'desc' }],
  };
  public selections: Attendant[] = [];
  private readonly _destroying$ = new Subject<void>();

  constructor(
    private appbarService: AppbarService,
    private stateService: StateService,
    private emailTypeService: EmailTypesService,
    private certificateService: CertificatesService,
    private adminAttendantsService: AdminAttendantsService,
    private attendantProfileService: AttendantProfileService,
    private notificationSystemService: NotificationSystemService,
    private router: Router,
    private emailService: EmailService
  ) {
    // Reference Data
    this.stateService
      .fetchData()
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        this.stateOptions = data;
      });

    this.certificateService
      .fetchData()
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        this.certOptions = data;
      });

    this.emailTypeService
      .fetchData()
      .pipe(takeUntil(this._destroying$))
      .subscribe(data => {
        this.emailOptions = data;
      });

    // Search
    this.adminAttendantsService
      .fetchAttendants(false)
      .pipe(takeUntil(this._destroying$))
      .subscribe((data: Attendant[]) => {
        this.searchResults = data;
        if (this.searchResults && this.searchResults.length > 0) {
          this.searchResults.forEach(x => (x['createdOnDate'] = x.createdOn.setHours(0, 0, 0, 0)));
        }
      });

    this.adminAttendantsService
      .getLoad()
      .pipe(takeUntil(this._destroying$))
      .subscribe((data: boolean) => {
        this.gridLoading = data;
      });
  }

  ngOnInit(): void {
    this.setPage();
    this.setKendoGrid();
    this.setKendoGridDefaultValues();
    this.setForm();
  }

  public ionViewWillEnter(): void {
    this.setPage();
    this.search();
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }

  public setPage(): void {
    this.appbarService.clear();
    this.appbarService.setPage('admin-attendants');
  }

  private setKendoGridDefaultValues(): void {
    this.state.filter = { logic: 'and', filters: [] };
    this.pageSize = KendoConstants.kendoMasterGridProperties.pageSize;
    this.pageNumber = KendoConstants.kendoMasterGridProperties.pageNumber;
    this.state.skip = KendoConstants.kendoMasterGridProperties.skip;
    this.state.sort = [{ field: 'approvalDate', dir: 'desc' }];
  }

  public setForm() {
    this.searchForm = new FormGroup({
      status: new FormControl(this.searchData.status, []),
      firstName: new FormControl(this.searchData.firstName, []),
      lastName: new FormControl(this.searchData.lastName, []),
      id: new FormControl(this.searchData.id, []),
      email: new FormControl(this.searchData.email, []),
      zipcode: new FormControl(this.searchData.zipcode, []),
      state: new FormControl(this.searchData.state, []),
      certifications: new FormControl(this.searchData.certifications, []),
    });

    this.emailForm = new FormGroup({
      email: new FormControl(this.emailData.email, [ValidationFunctions.requiredEmail]),
    });
  }

  public setCerts(data) {
    this.searchForm.controls['certifications'].setValue(data);
  }

  public setSelected(event) {
    this.selections = [];
    event.forEach(row => this.selections.push(row.dataItem));
  }

  public search() {
    if (this.searchForm.value['id']) {
      this.searchForm.value['userId'] = this.searchForm.value['id'];
    }
    if (this.searchForm.value['status'] === null) {
      this.searchForm.value['status'] = undefined;
    }
    this.adminAttendantsService.searchAttendants(this.searchForm.value);
  }

  public clear() {
    this.adminAttendantsService.clearAttendants();
    this.searchForm.reset();
  }

  public email() {
    if (this.emailForm.valid) {
      if (this.emailForm.value.email === '90 Day') {
        const IdEmailRequestArr: IdEmailRequest[] = [];
        this.selections.forEach(attendant => {
          IdEmailRequestArr.push(new IdEmailRequest({ userId: attendant.userId, email: attendant.email }));
        });
        this.emailService.reaffirm(IdEmailRequestArr);
      } else {
        const emailTos: string[] = [];
        this.selections.forEach(attendant => {
          emailTos.push(attendant.userId);
        });
        const newEmail = new EmailRequest();
        newEmail.emailTos = emailTos;
        newEmail.emailSubject = this.emailForm.value.email;
        newEmail.placeHolderInfo = { '{empty}': '' };
        switch (this.emailForm.value.email) {
          case 'Registration':
            newEmail.template = CommonConstants.templates.registrationTemplate;
            break;
          case 'Account Approved':
            newEmail.template = CommonConstants.templates.approvedTemplate;
            break;
          case 'Account Rejected':
            newEmail.template = CommonConstants.templates.rejectedTemplate;
            break;
          case 'Change Password':
            newEmail.template = CommonConstants.templates.passwordResetTemplate;
            break;
        }
        this.emailService.notify(newEmail);
      }
    } else {
      this.emailForm.get('email').markAsTouched();
    }
  }

  public goAttendantProfile(data: Attendant) {
    this.attendantProfileService.clear();
    this.adminAttendantsService.clear();
    this.attendantProfileService.setSearchParameters(data.userId);
    this.router.navigate(['/Attendant']);
  }

  public setKendoGrid() {
    this.columns = [
      {
        title: 'View Account',
        field: 'category',
        type: 'hyperLink',
        iconType: 'searchIcon',
        customText: 'View',
        sortable: false,
        filterable: false,
        width: 100,
      },
      {
        title: 'Status',
        field: 'status',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        title: 'First Name',
        field: 'firstName',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 125,
      },
      {
        title: 'Last Initial',
        field: 'lastName',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 75,
      },
      {
        title: 'ID',
        field: 'userId',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        title: 'Email',
        field: 'email',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 200,
      },
      {
        title: 'Zipcode',
        field: 'zip',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 100,
      },
      {
        title: 'State',
        field: 'state',
        type: 'text',
        sortable: true,
        filterable: true,
        width: 50,
      },
      {
        title: 'Preferred Hire Date',
        field: 'prefHireDate',
        type: 'date',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        title: 'Profile Creation Date',
        field: 'createdOnDate',
        type: 'date',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        title: 'Profile Approval Date',
        field: 'approvalDate',
        type: 'date',
        sortable: true,
        filterable: true,
        width: 150,
      },
      {
        title: '90 Day Contract Date',
        field: 'contractDate',
        type: 'date',
        sortable: true,
        filterable: true,
        width: 150,
      },
    ];
  }
}
