import { Component, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { RecaptchaComponent, RecaptchaErrorParameters } from 'ng-recaptcha-2';

// https://www.npmjs.com/package/ng-recaptcha#api-options

@Component({
  selector: 'CDMS-captcha-control',
  templateUrl: 'captcha-control.component.html',
  styleUrls: ['./captcha-control.component.scss'],
})
export class CaptchaControlComponent {
  @Input() show = true;
  @Input() size: 'normal' | 'compact' | 'invisible' = 'normal';

  @Output() resolved = new EventEmitter();
  @Output() errorEvent = new EventEmitter();

  @ViewChild('recaptcha', { static: true }) recaptcha: RecaptchaComponent;

  constructor() {}

  public onResolved(captchaResponse: string): void {
    this.resolved.emit();
  }

  public onError(errorDetails: RecaptchaErrorParameters): void {
    this.errorEvent.emit(errorDetails);
  }

  public reset(): void {
    this.recaptcha.reset();
  }

  public execute(): void {
    this.recaptcha.execute();
  }
}
