<div *ngIf="show">
  <div class="attendant-card-wrapper">
    <kendo-card class="attendant-card" [orientation]="'horizontal'">
      <kendo-card-header class="attendant-card-header">
        <div class="attendant-card-picture">
          <img
            title="Profile Picture"
            kendoCardMedia
            [src]="details?.photoUrl === '' ? '/assets/images/default-avatar.png' : details?.photoUrl"
            alt="'profile-picture" />
        </div>
      </kendo-card-header>

      <kendo-card-body class="attendant-card-body">
        <div class="row top-row">
          <div class="attendant-card-title">
            <p>{{ details?.firstName }} {{ details?.lastName.charAt(0) }}</p>
          </div>
          <p class="attendant-card-link" (click)="onClick()">
            {{ 'card_view' | langchange }}
          </p>
        </div>
        <div class="attendant-card-bodytext">
          <p>{{ details?.state }} {{ details?.zipCode }}</p>
        </div>
        <div *ngIf="details?.schedule" class="d-block">
          <div *ngIf="details?.schedule" class="attendant-card-bodylist">
            <p>{{ 'card_schedule' | langchange }}: {{ details?.schedule }}</p>
          </div>
        </div>
        <div *ngIf="details?.hourAvailability" class="d-block">
          <div *ngIf="details?.hourAvailability" class="attendant-card-bodytext">
            <p>{{ details?.hourAvailability }} {{ 'card_rate' | langchange }}</p>
          </div>
        </div>
        <div *ngIf="details?.snippet" class="attendant-card-bodytext m-t-10">
          <p>{{ details?.snippet }}</p>
        </div>
      </kendo-card-body>
    </kendo-card>
  </div>
</div>
