import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CommonFunctions {
  public getFormattedDate(dateString: string) {
    let date = '';
    if (dateString !== null && dateString !== '') {
      const currentTime = new Date(dateString);
      const month = currentTime.getMonth() + 1;
      const day = currentTime.getDate();
      const year = currentTime.getFullYear();
      const monthStr = month < 10 ? '0' + month.toString() : month.toString();
      const dayStr = day < 10 ? '0' + day.toString() : day.toString();
      const yearStr = year.toString();
      date = monthStr + '/' + dayStr + '/' + yearStr;
    }
    return date;
  }

  public getTrimmedString(inputString: string) {
    if (inputString !== null && inputString !== '') {
      inputString = inputString.trim();
    }
    return inputString;
  }

  public compareValues(key, order = 'asc') {
    return function (a, b) {
      if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
        return 0;
      }
      const varA = typeof a[key] === 'string' ? a[key].toUpperCase() : a[key];
      const varB = typeof b[key] === 'string' ? b[key].toUpperCase() : b[key];
      let comparison = 0;
      if (varA > varB) {
        comparison = 1;
      } else if (varA < varB) {
        comparison = -1;
      }
      return order === 'desc' ? comparison * -1 : comparison;
    };
  }
  public getStatusText(status: boolean) {
    return status ? 'A' : 'I';
  }

  public trimComment(text: string) {
    let trimText = text;
    if (trimText && trimText.length > 7) {
      trimText = trimText.substr(0, 7) + '...';
    }
    return trimText;
  }

  public getTimeFormatLookup() {
    const timeFormatLookup = [
      { Id: 1, Text: '24 hrs', Format: 'HH:mm', Placeholder: 'HH:MM' },
      { Id: 2, Text: '12 hrs', Format: 'hh:mm a', Placeholder: 'HH:MM A' },
    ];
    return timeFormatLookup;
  }

  public getValidDaysName(col: any) {
    let validDayName = '';
    const validDays: number[] = [];
    if (col.validDays) {
      const validDaysStr = col.validDays.split(',');
      for (let i = 0; i < validDaysStr.length; i++) {
        validDays.push(parseInt(validDaysStr[i], 10));
      }
      validDayName = this.getDaysAsText(validDays);
    }
    return validDayName;
  }

  public getDaysAsText(daysNumber: number[]): string {
    const daysString: string[] = [];
    daysNumber.forEach(function (val) {
      switch (val) {
        case 1:
          daysString.push('Sun');
          break;
        case 2:
          daysString.push('Mon');
          break;
        case 3:
          daysString.push('Tue');
          break;
        case 4:
          daysString.push('Wed');
          break;
        case 5:
          daysString.push('Thu');
          break;
        case 6:
          daysString.push('Fri');
          break;
        case 7:
          daysString.push('Sat');
          break;
      }
    });
    return daysString.toString();
  }

  public getDaysAsNumbers(daysString: string): number[] {
    const daysNumber: number[] = [];
    if (daysString !== null && daysString !== '') {
      const daysStringArr = daysString.split(',');
      daysStringArr.forEach(day => {
        const val = day !== null && day !== '' ? day.toLowerCase() : '';
        switch (val) {
          case 'sun':
            daysNumber.push(1);
            break;
          case 'mon':
            daysNumber.push(2);
            break;
          case 'tue':
            daysNumber.push(3);
            break;
          case 'wed':
            daysNumber.push(4);
            break;
          case 'thu':
            daysNumber.push(5);
            break;
          case 'fri':
            daysNumber.push(6);
            break;
          case 'sat':
            daysNumber.push(7);
            break;
        }
      });
    }
    return daysNumber;
  }

  public getDateWithoutTime(date?: Date) {
    if (date && date !== null) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0, 0, 0));
    }
    return date;
  }

  public getTimeWithoutDate(date?: Date) {
    if (date && date !== null) {
      date = new Date(
        Date.UTC(
          date.getFullYear(),
          date.getMonth(),
          date.getDate(),
          date.getHours(),
          date.getMinutes(),
          date.getSeconds(),
          0
        )
      );
    }
    return date;
  }

  public getDateWithTime(date?: Date, hours?: number, mins?: number, secs?: number) {
    if (date && hours && mins && secs) {
      date = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate(), hours, mins, secs, 0));
    }
    return date;
  }

  public getToFixed(precesion: number, value: number) {
    if (value && value !== null) {
      value = Number(value.toFixed(precesion));
    }
    return value;
  }

  public floatify(num: number) {
    return parseFloat(num.toFixed(10));
  }

  public unsubscribeValueChanges(sub: Subscription) {
    if (sub) {
      sub.unsubscribe();
    }
  }

  public getBrowserName() {
    const agent = window.navigator.userAgent.toLowerCase();
    switch (true) {
      case agent.indexOf('edge') > -1:
        return 'edge';
      case agent.indexOf('opr') > -1 && !!(<any>window).opr:
        return 'opera';
      case agent.indexOf('chrome') > -1 && !!(<any>window).chrome:
        return 'chrome';
      case agent.indexOf('trident') > -1:
        return 'ie';
      case agent.indexOf('firefox') > -1:
        return 'firefox';
      case agent.indexOf('safari') > -1:
        return 'safari';
      default:
        return 'other';
    }
  }

  public validateLeapYear(date) {
    const dtArr = date.split('/');
    let isValid = false;
    if (dtArr && dtArr.length === 3) {
      isValid = !(
        Number(dtArr[0]) === 2 &&
        Number(dtArr[1]) === 29 &&
        !(Number(dtArr[2]) % 4 === 0 && (Number(dtArr[2]) % 100 !== 0 || Number(dtArr[2]) % 400 === 0))
      );
    }

    return isValid;
  }

  public convertStrArrayToIntArray(str, sep) {
    sep = typeof sep !== 'undefined' ? sep : '';
    return str
      .split(sep)
      .filter(function (val) {
        if (val === 'undefined' || val === null || val === '' || isNaN(val)) {
          return false;
        }
        return true;
      })
      .map(function (val) {
        return parseInt(val, 10);
      });
  }

  public base64ToArrayBuffer(base64) {
    const binaryString = window.atob(base64);
    const binaryLen = binaryString.length;
    const bytes = new Uint8Array(binaryLen);
    for (let i = 0; i < binaryLen; i++) {
      const ascii = binaryString.charCodeAt(i);
      bytes[i] = ascii;
    }
    return bytes;
  }

  public setSpellCheckAttribute(document: Document) {
    const selector = 'input.k-textbox, input.k-input ,textarea';
    const textFields = document.querySelectorAll(selector);
    for (let i = 0; i < textFields.length; i++) {
      textFields[i].setAttribute('spellcheck', 'false');
    }
  }

  public formatNumeric(value: number, precision: number, fixedDecimal: number): string {
    if (value !== null) {
      // If precision was sent in, round value to the precision
      if (precision !== null) {
        const roundBy = Math.pow(10, precision);
        value = Math.round(value * roundBy) / roundBy;
      }
      // Handle formatting (number of digits after the decimal)
      if (fixedDecimal !== null) {
        return value.toFixed(fixedDecimal);
      }
      return value.toString();
    }
    return '';
  }

  public formatAmount(value: number): string {
    if (value) {
      return '$' + this.addThousandSperator(value.toFixed(2));
    } else if (value === 0) {
      return '$' + value.toFixed(2);
    }
    return '';
  }

  public formatAccounting(value: number): string {
    if (value) {
      const fVal = '$' + this.addThousandSperator(Math.abs(value).toFixed(2));
      if (value < 0) {
        return '(' + fVal + ')';
      }
      return fVal;
    } else if (value === 0) {
      return '$' + value.toFixed(2);
    }
    return '';
  }

  private addThousandSperator(inAmount) {
    const partsAmount = inAmount.toString().split('.');
    partsAmount[0] = partsAmount[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    return partsAmount.join('.');
  }

  private RoundTime(dt: Date) {
    let roundedDate: Date;
    const quaterTicks = 15 * 60 * 1000;
    const timeTicks = dt.getMinutes() * 60 * 1000;
    const mod = timeTicks % quaterTicks;
    if (mod > quaterTicks / 2) {
      const interval = 15 * 60 * 1000; // 15 minutes in milliseconds
      roundedDate = new Date(Math.ceil(dt.getTime() / interval) * interval);
    } else {
      roundedDate = new Date(Math.floor(dt.getTime() / quaterTicks) * quaterTicks);
    }

    return roundedDate;
  }

  public CalculateCalHrs(inTime: Date, outTime: Date) {
    const inTimeInMinutes = inTime.getHours() * 60 + inTime.getMinutes();
    const outTimeInMinutes = outTime.getHours() * 60 + outTime.getMinutes();
    return (outTimeInMinutes - inTimeInMinutes) / 60;
  }

  public IsMinutesInQuarter(time: Date) {
    const timeInMinutes = time.getMinutes();
    if (timeInMinutes % 15 === 0) {
      return true;
    } else {
      return false;
    }
  }

  public CalculateTimeFromCalhrs(inTime: Date, calHrs: number) {
    const outTime = inTime;
    const hours = Math.floor(calHrs);
    let remain = calHrs - hours;

    const m = 60 * remain;
    const min = Math.floor(m);
    remain = m - min;

    const s = 60 * remain;
    const sec = Math.floor(s);
    outTime.setHours(hours, min, sec, 0);
    return outTime;
  }

  public parseQueryString(paramsObj: any) {
    const queryString = Object.keys(paramsObj)
      .map(key => {
        if (paramsObj[key]) {
          return encodeURIComponent(key) + '=' + encodeURIComponent(paramsObj[key]);
        }
      })
      .filter(Boolean)
      .join('&');
    return queryString ? '?' + queryString : '';
  }

  public formatNumber(value: number, decimals: number = 2): string {
    if (value) {
      return value.toFixed(decimals);
    }
    return '0';
  }

  public getRefeshDate(obj: any[]): Date {
    if (obj && obj.length > 0) {
      const mostRecentDate = new Date(
        Math.max.apply(
          null,
          obj.map(e => new Date(e.updatedOn))
        )
      );
      return mostRecentDate;
    } else {
      return null;
    }
  }

  public isObject(obj: any): number {
    if (obj) {
      return Object.keys(obj).length;
    } else {
      return 0;
    }
  }

  public addHoursToDate(value: Date, hours: number = 3) {
    if (value) {
      value.setHours(value.getHours() + hours);
    }
  }

  public setObfuscatedSSN(ssn: string) {
    if (ssn) {
      if (ssn.length > 4) {
        ssn = ssn.substring(ssn.length - 4, ssn.length);
      }
      return 'XXX-XX-' + ssn;
    } else {
      return ssn;
    }
  }

  public trim(x) {
    if (!x.value) {
      return x
        ? (x = x
            .replace(/(^\s*)|(\s*$)/gi, '')
            .replace(/[ ]{2,}/gi, ' ')
            .replace(/\n +/, '\n'))
        : '';
    } else {
      x.value = x.value
        .replace(/(^\s*)|(\s*$)/gi, '')
        .replace(/[ ]{2,}/gi, ' ')
        .replace(/\n +/, '\n');
      return;
    }
  }

  public check_special_char(event) {
    const k = event.charCode;
    return k === 8 || (k >= 48 && k <= 57);
  }

  public projectLocalDateTimeAsUtc(dateVal: Date) {
    return new Date(
      dateVal.getFullYear() +
        '-' +
        (dateVal.getMonth() + 1) +
        '-' +
        dateVal.getDate() +
        ' ' +
        dateVal.getHours() +
        ':' +
        dateVal.getMinutes() +
        ':' +
        dateVal.getSeconds() +
        'Z'
    );
  }

  public resetFormDirty(form: FormGroup) {
    form.markAsPristine();
  }

  public removeExtraSpaces(str: string) {
    return str.replace(/\s\s+/g, ' ').trim();
  }

  public isNull(str: string, str2: string) {
    return str ? str : str2;
  }

  public addSpaceForPascalCase(str: string) {
    if (!str) {
      return str;
    }
    return str.replace(/([A-Z])/g, ' $1').trim();
  }

  public setPlaceHolder(elementId: string, form: FormGroup, placeholderText: string) {
    if (this.getBrowserName() === 'ie') {
      document.getElementById(elementId).setAttribute('placeholder', placeholderText);
      if (!form.dirty) {
        form.markAsPristine();
      }
    } else {
      document.getElementById(elementId).setAttribute('placeholder', placeholderText);
    }
  }

  public setMaskedFocus(element: any) {
    if (element && element.target && element.target.value) {
      const elmValue = element.target.value;
      const numericString = elmValue.replace(/[^0-9]/g, '');
      const position = numericString && numericString.length > 0 ? elmValue.indexOf('_') : 0;
      setTimeout(function () {
        if (element.target.setSelectionRange) {
          element.target.setSelectionRange(position, position);
        }
      }, 1);
    }
  }

  public toggleMultiSelect(hide: boolean) {
    const ta = document.getElementsByClassName('k-reset') as HTMLCollectionOf<HTMLElement>;
    if (ta[0]) {
      if (hide) {
        ta[0].style.display = 'none';
      } else {
        ta[0].style.display = 'block';
      }
    }
  }

  public onMultiSelectClose(event: any, multiselect: any) {
    event.preventDefault();
    // Close the list if the component is no longer focused
    setTimeout(() => {
      if (!multiselect.wrapper.nativeElement.contains(document.activeElement)) {
        multiselect.toggle(false);
      }
    });
  }

  public getItemFromOptions(id: string, options: any[], idName) {
    for (const option of options) {
      if (this.getProp(option, idName) === id) {
        return option;
      }
    }
    return id;
  }

  private getProp(obj, prop) {
    if (typeof obj !== 'object') {
      throw new Error('getProp: obj is not an object');
    }
    if (typeof prop !== 'string') {
      throw new Error('getProp: prop is not a string');
    }

    // Replace [] notation with dot notation
    prop = prop.replace(/\[["'`](.*)["'`]\]/g, '.$1');

    return prop.split('.').reduce(function (prev, curr) {
      return prev ? prev[curr] : undefined;
    }, obj || self);
  }

  public replaceNotificationSubstring(message: string, placeholderText: string, replacePlaceholderTextWith: string) {
    return message.replace(placeholderText, replacePlaceholderTextWith);
  }
}
