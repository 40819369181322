import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';

import { Status } from '../../constants/app-constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-status-container-control',
  templateUrl: './status-container-control.component.html',
  styleUrls: ['./status-container-control.component.scss'],
})
export class StatusContainerControl implements OnInit {
  @Input() show = true;
  @Input() status: Status = 0;
  @Input() statusData = {
    photo: null,
    about: null,
    snippet: null,
    all: null,
  };

  @Output() clickEvent = new EventEmitter();

  public statusForm;

  constructor() {}

  ngOnInit() {
    this.statusForm = new FormGroup({
      photo: new FormControl(this.statusData.photo, []),
      about: new FormControl(this.statusData.about, []),
      snippet: new FormControl(this.statusData.snippet, []),
    });
  }

  public setStatus(field: string, choice: boolean) {
    switch (field) {
      case 'photo':
        this.statusData.photo = choice;
        this.statusData.all = null;
        break;
      case 'about':
        this.statusData.about = choice;
        this.statusData.all = null;
        break;
      case 'snippet':
        this.statusData.snippet = choice;
        this.statusData.all = null;
        break;
      case 'all':
        this.statusData.all = choice;
        if (this.statusData.photo !== null && this.statusData.photo !== undefined) {
          this.statusData.photo = choice;
        }
        if (this.statusData.about !== null && this.statusData.about !== undefined) {
          this.statusData.about = choice;
        }
        if (this.statusData.snippet !== null && this.statusData.snippet !== undefined) {
          this.statusData.snippet = choice;
        }
        break;
    }
    const { all: _, ...newObj } = this.statusData;
    this.clickEvent.emit(newObj);
  }
}
