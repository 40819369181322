import { Component, Input, OnInit } from '@angular/core';

import { AppConstants, Status, StatusSpanish } from '../../constants/app-constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-status-indicator-control',
  templateUrl: './status-indicator-control.component.html',
  styleUrls: ['./status-indicator-control.component.scss'],
})
export class StatusIndicatorControl {
  @Input() show = true;
  @Input() label: string;
  @Input()
  public set status(data: number | string) {
    if (this.isNumber(data)) {
      this.statusStr = Status[data];
    } else {
      this.statusStr = data as string;
    }
    if (this.statusStr === 'New') {
      this.statusStr = 'Pending';
    }
  }

  public isNumber(n) {
    return !isNaN(parseFloat(n)) && !isNaN(n - 0);
  }

  public statusStr = 'Pending';

  constructor() {}

  public displayStatus(status: string) {
    if (AppConstants.selectedLanguage === 'es') {
      return StatusSpanish[Status[status]];
    }
    return status;
  }
}
