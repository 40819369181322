import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FabAlign, FabOffset } from '@progress/kendo-angular-buttons';
import { PositionMode } from '@progress/kendo-angular-popup';
import { SVGIcon } from '@progress/kendo-svg-icons';

import { Button, Plane, Size, Type } from '../constants/common-type-constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-common-button-control',
  templateUrl: './common-button-control.component.html',
  styleUrls: ['../assets/scss/_common-components.scss'],
})
export class CommonButtonControlComponent {
  @Input()
  public set size(data: Size) {
    this._size = data;
  }
  public get size() {
    return this._size;
  }

  @Input()
  public set button(data: Button) {
    this._button = data;
  }
  public get button() {
    return this._button;
  }

  @Input()
  public set type(data: Type) {
    this._type = data;
  }
  public get type() {
    return this._type;
  }

  @Input()
  public set plane(data: Plane) {
    this._plane = data;
    if (this.titleHidden) {
      this._plane = 'vertical';
    }
  }
  public get plane() {
    return this._plane;
  }

  @Input() show = true;
  @Input() disabled = false;
  @Input() title: string;
  @Input() titleHidden = false;
  @Input() icon: SVGIcon;
  @Input() float = false;
  @Input() positionMode: PositionMode;
  @Input() align: FabAlign;
  @Input() offset: FabOffset;
  @Input() tooltip = false;
  @Input() tooltipText: string;

  @Output() clickEvent = new EventEmitter();

  private _size: Size = 'medium';
  private _type: Type = 'primary';
  private _button: Button = 'standard';
  private _plane: Plane = 'vertical';

  constructor() {}

  public click(event): void {
    if (this.disabled !== true) {
      this.clickEvent.emit(event);
    }
  }
}
