import { Pipe, PipeTransform } from '@angular/core';

import { AppConstants } from '../../constants/app-constants';

@Pipe({
  name: 'langchange',
  pure: false,
})
export class LangchangePipe implements PipeTransform {
  constructor() {}

  transform(key: any): any {
    if (AppConstants.preferredLanguageData) {
      const transFromText = AppConstants.preferredLanguageData[key];
      if (transFromText && transFromText.value) {
        return transFromText.value;
      }
    }
    return '';
  }
}
