import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ButtonsModule } from '@progress/kendo-angular-buttons';
import { DateInputsModule } from '@progress/kendo-angular-dateinputs';
import { DialogsModule } from '@progress/kendo-angular-dialog';
import { DropDownsModule } from '@progress/kendo-angular-dropdowns';
import { ExcelModule, GridModule } from '@progress/kendo-angular-grid';
import { InputsModule } from '@progress/kendo-angular-inputs';
import { LayoutModule } from '@progress/kendo-angular-layout';
import { TooltipModule } from '@progress/kendo-angular-tooltip';
import { UploadsModule } from '@progress/kendo-angular-upload';
import { RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha-2';
import { captcha } from 'src/environments/environment';

import { CommonComponentsModule } from '../common-components/common-components.module';
import { AppbarControlComponent } from './appbar/appbar-control.component';
import { AttendantCardControl } from './attendant-card/attendant-card-control.component';
import { CaptchaControlComponent } from './captcha/captcha-control.component';
import { LangchangePipe } from './pipes/langchange.pipe';
import { StatusContainerControl } from './status-container/status-container-control.component';
import { StatusIndicatorControl } from './status-indicator/status-indicator-control.component';
import { WeeklyWorkScheduleControlComponent } from './weekly-work-schedule/weekly-work-schedule-control.component';

// We need to sign up for google captcha and get codes of our own: https://developers.google.com/recaptcha
const captchaSettings: RecaptchaSettings = { siteKey: captcha.siteKey };
@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    DialogsModule,
    TooltipModule,
    GridModule,
    ExcelModule,
    ButtonsModule,
    InputsModule,
    LayoutModule,
    DropDownsModule,
    DateInputsModule,
    CommonComponentsModule,
    UploadsModule,
    RecaptchaModule,
  ],
  declarations: [
    AppbarControlComponent,
    WeeklyWorkScheduleControlComponent,
    CaptchaControlComponent,
    StatusContainerControl,
    AttendantCardControl,
    StatusIndicatorControl,
    LangchangePipe,
  ],
  exports: [
    AppbarControlComponent,
    WeeklyWorkScheduleControlComponent,
    CaptchaControlComponent,
    StatusContainerControl,
    AttendantCardControl,
    StatusIndicatorControl,
    LangchangePipe,
  ],
  providers: [
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: captchaSettings,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
