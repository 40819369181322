import { Injectable, OnDestroy } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { StateAbbr } from 'src/app/constants/app-types';
import { states } from 'src/app/services/mock-data';
import { ProviderDirectoryFunctionService } from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';

@Injectable({
  providedIn: 'root',
})
export class StateService implements OnDestroy {
  private _entity: BehaviorSubject<StateAbbr[]> = new BehaviorSubject<StateAbbr[]>([]);

  private readonly _destroying$ = new Subject<void>();

  constructor(private searchService: ProviderDirectoryFunctionService) {}

  async search() {
    const data: StateAbbr[] = states;
    this._entity.next(data);
  }

  public fetchData(): Observable<StateAbbr[]> {
    if (this._entity.getValue.length === 0) {
      this.search();
    }
    return this._entity;
  }

  ngOnDestroy(): void {
    this._destroying$.next(null);
    this._destroying$.complete();
  }
}
