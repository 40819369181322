<div *ngIf="show" class="schedule-wrapper">
  <table class="schedule-table">
    <thead>
      <tr>
        <th class="schedule-label"></th>
        <th class="schedule-label">{{ displayDay(0) }}</th>
        <th class="schedule-label">{{ displayDay(1) }}</th>
        <th class="schedule-label">{{ displayDay(2) }}</th>
        <th class="schedule-label">{{ displayDay(3) }}</th>
        <th class="schedule-label">{{ displayDay(4) }}</th>
        <th class="schedule-label">{{ displayDay(5) }}</th>
        <th class="schedule-label">{{ displayDay(6) }}</th>
      </tr>
    </thead>
    <tr>
      <td class="schedule-label">{{ displayTime(0) }}</td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 0) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 0)"
        (click)="set(0, 0)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 1) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 1)"
        (click)="set(0, 1)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 2) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 2)"
        (click)="set(0, 2)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 3) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 3)"
        (click)="set(0, 3)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 4) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 4)"
        (click)="set(0, 4)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 5) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 5)"
        (click)="set(0, 5)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(0, 6) }}"
        tabindex="0"
        (keyup.Enter)="set(0, 6)"
        (click)="set(0, 6)"></td>
    </tr>
    <tr>
      <td class="schedule-label">{{ displayTime(1) }}</td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 0) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 0)"
        (click)="set(1, 0)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 1) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 1)"
        (click)="set(1, 1)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 2) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 2)"
        (click)="set(1, 2)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 3) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 3)"
        (click)="set(1, 3)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 4) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 4)"
        (click)="set(1, 4)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 5) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 5)"
        (click)="set(1, 5)"></td>
      <td
        class="schedule-cell cursor-{{ !readOnly }} schedule-cell-{{ getSelected(1, 6) }}"
        tabindex="0"
        (keyup.Enter)="set(1, 6)"
        (click)="set(1, 6)"></td>
    </tr>
  </table>
</div>
