<div>
  <kendo-grid
    #grid
    class="custom-grid"
    [id]="id"
    [data]="gridData"
    [kendoGridBinding]="kendoGridBindingData"
    [loading]="loading"
    [pageSize]="pageSize"
    [skip]="skip"
    [scrollable]="scrollable"
    [pageable]="{
      buttonCount: 5,
      info: false,
      pageSizes: pageable,
      position: 'bottom',
      previousNext: true,
      responsive: true,
      type: 'numeric',
    }"
    [selectable]="selectableSettings"
    [sortable]="sortable"
    [sort]="sort"
    [ngClass]="styleClassesArray"
    [rowClass]="rowCallbackClass"
    [resizable]="resizable"
    [reorderable]="reorderable"
    [filterable]="'menu'"
    [filter]="filter"
    (filterChange)="filterChangeEvent($event)"
    (sortChange)="sortChangeEvent($event)"
    (pageChange)="pageChangeEvent($event)"
    (selectionChange)="selectionChangeEvent($event)"
    (keydown.esc)="escHandler()"
    (keydown.control.enter)="ctrlEnterEvent($any($event))"
    [selectedKeys]="selectedKeysInput ? selectedKeys : mySelection"
    (cellClick)="inlineEdit || modalEdit ? rowClickHandler($event) : false"
    [kendoGridSelectBy]="selectedby"
    (add)="addClickHandler()"
    (save)="saveClickHandler($event)"
    (delete)="deleteRow($any($event))"
    (cancel)="escHandler()">
    <!-- TOOLBAR -->
    <ng-template kendoGridToolbarTemplate>
      <div
        class="action-head action-head-fixed-height action-head-sm header-style-{{ headerStyle }} row m-0"
        *ngIf="
          this.title ||
          inlineCreate ||
          modalCreate ||
          toolbarDelete ||
          toolbarCopy ||
          toolbarRefresh ||
          toolbarCustomButton
        ">
        <div class="grid-toolbar-heading" *ngIf="this.title">
          <h6>{{ this.title }}</h6>
        </div>

        <div kendoTooltip class="flex-end" [showAfter]="1250">
          <div *ngIf="inlineCreate || modalCreate" title="{{ titleTooltip }}" class="toolbar-button">
            <a kendobutton class="toolbar-button-link" (click)="addClickHandler()" id="createBtnToolbar">
              <i class="k-icon k-i-plus-outline"></i>
              <span>{{ createButtonLabel }}</span>
            </a>
          </div>

          <div *ngIf="toolbarCopy" title="Copy Selected Records" class="toolbar-button">
            <a kendobutton class="toolbar-button-link" (click)="emitSelectedItems('Copy')" id="copyBtnToolbar">
              <i class="k-icon k-i-copy"></i>
              <span>Copy</span>
            </a>
          </div>

          <div *ngIf="toolbarDelete" title="Delete Selected Records" class="toolbar-button">
            <a kendobutton class="toolbar-button-link" (click)="emitSelectedItems('Delete')" id="deleteBtnToolbar">
              <i class="k-icon k-i-delete"></i>
              <span>Delete</span>
            </a>
          </div>

          <div *ngIf="toolbarRefresh" title="Refresh Grid" class="action-head-fixed-height toolbar-button">
            <a kendobutton class="toolbar-button-link" (click)="emitSelectedItems('Refresh')" id="refreshBtnToolbar">
              <i class="k-icon k-i-refresh"></i>
              <span>Refresh</span>
            </a>
          </div>

          <div *ngIf="toolbarExcel" title="Export Records" class="action-head-fixed-height toolbar-button">
            <a kendoGridExcelCommand class="toolbar-button-link" id="exportBtnToolbar">
              <i class="k-icon k-i-file-excel fs-23 v-a-t"></i>
              <span>Export</span>
            </a>
          </div>

          <div *ngIf="toolbarCustomButton" title="toolbarCustomButtonText" class="toolbar-button">
            <a kendobutton class="toolbar-button-link" (click)="emitSelectedItems('Custom')" id="customBtnToolbar">
              <i class="{{ icon }}"></i>
              <span>{{ toolbarCustomButtonText }}</span>
            </a>
          </div>
        </div>
      </div>
    </ng-template>

    <!-- CHECKBOX COLUMN -->
    <kendo-grid-checkbox-column [width]="50" *ngIf="showCheckBoxCol" [showSelectAll]="showCheckBoxColSelectAll">
    </kendo-grid-checkbox-column>

    <!-- GRID COLUMNS -->
    <ng-container *ngFor="let column of columns">
      <kendo-grid-column
        #gridColumn
        *ngIf="column.type !== 'command'"
        field="{{ column.field }}"
        title="{{ column.title }}"
        format="{{
          column.type === 'date' || column.type === 'datelte' || column.type === 'dategte'
            ? 'MM/dd/yyyy'
            : column.format
        }}"
        [filter]="
          column.type === 'date' || column.type === 'datelte' || column.type === 'dategte' ? 'date' : $any(column.type)
        "
        [width]="column.width"
        [filterable]="column.filterable === false ? false : true"
        [class]="column.className"
        [sortable]="column.sortable === false ? false : true"
        [class]="column.editType"
        [hidden]="column.hidden"
        [editable]="column.editable">
        <!-- FILTERS -->
        <ng-container [ngSwitch]="column.type">
          <ng-template
            *ngSwitchCase="'numeric'"
            kendoGridFilterMenuTemplate
            let-filter
            let-filterService="filterService"
            let-dataItem="dataItem">
            <kendo-grid-numeric-filter-menu
              [extra]="false"
              [column]="$any(column)"
              [filterService]="filterService"
              [filter]="filter"
              [format]="column?.format !== undefined ? column.format : 'n0'"
              [decimals]="column?.decimals !== undefined ? column.decimals : 0">
              <kendo-filter-eq-operator
                *ngIf="column.filterOperators ? column.filterOperators.indexOf('eq') > -1 : true">
              </kendo-filter-eq-operator>
              <kendo-filter-gte-operator
                *ngIf="column.filterOperators ? column?.filterOperators.indexOf('gte') > -1 : true">
              </kendo-filter-gte-operator>
              <kendo-filter-lte-operator
                *ngIf="column.filterOperators ? column?.filterOperators.indexOf('lte') > -1 : true">
              </kendo-filter-lte-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'numericgte'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
            let-dataItem="dataItem">
            <kendo-grid-numeric-filter-menu
              [extra]="false"
              [column]="column"
              [filterService]="filterService"
              [filter]="filter"
              [format]="'n0'"
              [decimals]="0"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'text'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'statusfilter'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <CDMS-Portal-U2-dropdown-filter
              [filter]="filter"
              [data]="statuses"
              textField="statusText"
              valueField="statusText"
              [filterService]="filterService">
            </CDMS-Portal-U2-dropdown-filter>
          </ng-template>

          <ng-template
            *ngSwitchCase="'autocomplete'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'textsw'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="startswith">
              <kendo-filter-timertswith-operator></kendo-filter-timertswith-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'updatedby'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'texthover'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'fixedtwo'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
            let-dataItem="dataItem">
            <kendo-grid-numeric-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [format]="'n2'"
              [decimals]="2"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'amount'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
            let-dataItem="dataItem">
            <kendo-grid-numeric-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [format]="'n2'"
              [decimals]="2"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'accounting'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService"
            let-dataItem="dataItem">
            <kendo-grid-numeric-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              [format]="'n2'"
              [decimals]="2"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-numeric-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'time'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'date'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-date-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="eq">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'dategte'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-date-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'datelte'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-date-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="lte">
              <kendo-filter-lte-operator></kendo-filter-lte-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'datetime'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-date-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="gte">
              <kendo-filter-gte-operator></kendo-filter-gte-operator>
            </kendo-grid-date-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'hyperLink'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'boolean'"
            kendoGridFilterMenuTemplate
            let-filter
            let-filterService="filterService">
            <kendo-grid-messages
              filterIsFalse="{{ column.booleanDisplayValue !== undefined ? column.booleanDisplayValue.false : 'No' }}"
              filterIsTrue="{{ column.booleanDisplayValue !== undefined ? column.booleanDisplayValue.true : 'Yes' }}">
            </kendo-grid-messages>
            <kendo-grid-boolean-filter-menu
              [extra]="false"
              [column]="$any(column)"
              [filter]="filter"
              [filterService]="filterService"
              operator="eq">
            </kendo-grid-boolean-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'select'"
            kendoGridFilterMenuTemplate
            let-filter
            let-filterService="filterService"
            let-dataItem="dataItem">
            <cfs-multiselect-filter-menu
              [isPrimitive]="column?.valueField === undefined ? true : false"
              [field]="column.field"
              [valueField]="column?.valueField"
              [textField]="column?.textField"
              [filterService]="filterService"
              [currentFilter]="filter"
              [data]="column.options">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
            </cfs-multiselect-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'selectWithId'"
            kendoGridFilterMenuTemplate
            let-filter
            let-filterService="filterService"
            let-dataItem="dataItem">
            <cfs-multiselect-filter-menu
              [isPrimitive]="false"
              [field]="column.field"
              [valueField]="column?.valueField"
              [textField]="column?.textField"
              [filterService]="filterService"
              [currentFilter]="filter"
              [data]="column.options">
              <kendo-filter-eq-operator></kendo-filter-eq-operator>
            </cfs-multiselect-filter-menu>
          </ng-template>

          <ng-template
            *ngSwitchCase="'lookup'"
            kendoGridFilterMenuTemplate
            let-filter
            let-column="column"
            let-filterService="filterService">
            <kendo-grid-string-filter-menu
              [extra]="false"
              [column]="column"
              [filter]="filter"
              [filterService]="filterService"
              operator="contains">
              <kendo-filter-contains-operator></kendo-filter-contains-operator>
            </kendo-grid-string-filter-menu>
          </ng-template>
        </ng-container>

        <!-- TYPE / EDIT TEMPLATES -->
        <ng-container *ngIf="column.redirect">
          <ng-template kendoGridCellTemplate let-col class="link">
            <a (click)="redirect(col, column.field)">
              {{ col[column.field] }}
              <ng-container *ngIf="column.iconType !== undefined && col[column.field]">
                <kendo-svgicon [icon]="allIcons[column.iconType]"></kendo-svgicon>
              </ng-container>
            </a>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.conditionalRedirect">
          <ng-template kendoGridCellTemplate let-dataItem let-col>
            <a (click)="redirect(col, column.field)">
              {{ columnCallbackExpression(dataItem, column.field) }}
            </a>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.function">
          <ng-template kendoGridCellTemplate let-dataItem let-col>
            {{ columnCallbackExpression(dataItem, column.field) }}
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'date' || column.type === 'dategte' || column.type === 'datelte'">
          <ng-template kendoGridCellTemplate let-col>
            {{ getDisplayValue(col[column.field], column) | date: 'MM/dd/yyyy' | nulldate }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{ getDisplayValue(formGroup.get(column.field).value, column) | date: 'MM/dd/yyyy' }}
            </div>
            <ng-template #elseBlock>
              <kendo-datepicker
                #startDate
                class="text-editor formControlTabAccessible"
                [formControl]="formGroup.controls[column.field]"
                [format]="'MM/dd/yyyy'"
                formatPlaceholder="formatPattern"
                placeholder="MM/dd/yyyy"
                (keydown.esc)="escHandler()"
                [min]="commonConstants.minSmallDate"
                [max]="commonConstants.maxSmallDate"
                [disabledDates]="column?.disabledDates"
                [disabledDatesValidation]="column?.disabledDatesValidation"
                [readOnlyInput]="column?.readOnlyInput">
              </kendo-datepicker>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container
          *ngIf="column.type === 'datetime' || column.type === 'datetimegte' || column.type === 'datetimelte'">
          <ng-template kendoGridCellTemplate let-col>
            {{ col[column.field] | date: 'MM/dd/yy - HH:mm' }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{ formGroup.get(column.field).value | date: 'MM/dd/yy - HH:mm' }}
            </div>
            <ng-template #elseBlock>
              <kendo-datepicker
                #startDate
                class="text-editor formControlTabAccessible"
                [formControl]="formGroup.controls[column.field]"
                [format]="'MM/dd/yy - HH:mm'"
                formatPlaceholder="formatPattern"
                placeholder="MM/dd/yy"
                (keydown.esc)="escHandler()"
                [min]="commonConstants.kendoDatePickerMinValue"
                [max]="commonConstants.kendoDatePickerMaxValue">
              </kendo-datepicker>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'updatedby'">
          <ng-template kendoGridCellTemplate let-col>
            <div kendoTooltip position="right">
              <a title="{{ col['updatedByName'] }}">
                {{ col[column.field] }}
              </a>
            </div>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'texthover'">
          <ng-template kendoGridCellTemplate let-col>
            <ng-container *ngFor="let item of col.denialReasondDetails">
              <!-- Make denialReasondDetails into a generic term -->
              <span kendoTooltip class="separator-comma" position="right" title="{{ item.description }}">
                {{ item.denialReasonCode }}
              </span>
            </ng-container>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div>
              <input
                type="text"
                title="Text Hover"
                class="k-textbox text-editor"
                [formControl]="formGroup.get(column.field)" />
            </div>
            <span class="inline-error">
              <ng-container *ngIf="formGroupControls[column.field]?.errors">
                <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                  {{ errorMessage[error.key] }}
                </ng-container>
              </ng-container>
            </span>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'hyperLink'">
          <ng-template kendoGridCellTemplate let-col let-dataItem>
            <a
              (click)="
                column.controlClickEvent ? gotoEdit(dataItem, column.controlClickEvent) : gotoEdit(dataItem, undefined)
              ">
              {{ column.customText !== undefined ? column.customText : col[column.field] }}
              <kendo-svgicon [icon]="save"></kendo-svgicon>
            </a>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'amount'">
          <ng-template kendoGridCellTemplate let-col>
            {{ commonFunction.formatAmount(col[column.field]) }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{ commonFunction.formatAmount(formGroup.get(column.field).value) }}
            </div>
            <ng-template #elseBlock>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'accounting'">
          <ng-template kendoGridCellTemplate let-col>
            {{ commonFunction.formatAccounting(col[column.field]) }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{ commonFunction.formatAccounting(formGroup.get(column.field).value) }}
            </div>
            <ng-template #elseBlock>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'fixedtwo'">
          <ng-template kendoGridCellTemplate let-col>
            {{ col[column.field] !== null && col[column.field] !== '' ? col[column.field].toFixed(2) : '' }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{
                formGroup.get(column.field).value !== null && formGroup.get(column.field).value !== ''
                  ? formGroup.get(column.field).value.toFixed(2)
                  : ''
              }}
            </div>
            <ng-template #elseBlock>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'fixedtwowithzero'">
          <ng-template kendoGridCellTemplate let-col>
            {{ col[column.field] !== null && col[column.field] !== '' ? col[column.field].toFixed(2) : '0.00' }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{
                formGroup.get(column.field).value !== null && formGroup.get(column.field).value !== ''
                  ? formGroup.get(column.field).value.toFixed(2)
                  : '0.00'
              }}
            </div>
            <ng-template #elseBlock>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'boolean'">
          <ng-template kendoGridCellTemplate let-col>
            {{
              column.booleanDisplayValue !== undefined
                ? col[column.field] === true
                  ? column.booleanDisplayValue.true
                  : column.booleanDisplayValue.false
                : col[column.field] === true
                  ? 'Y'
                  : 'N'
            }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{
                column.booleanDisplayValue !== undefined
                  ? formGroup.get(column.field).value === true
                    ? column.booleanDisplayValue.true
                    : column.booleanDisplayValue.false
                  : formGroup.get(column.field).value === true
                    ? 'Y'
                    : 'N'
              }}
            </div>
            <ng-template #elseBlock>
              <div>
                <input
                  title="Checkbox"
                  type="checkbox"
                  kendoCheckBox
                  [formControl]="formGroup.get(column.field)"
                  (click)="column.controlClickEvent(formGroup.controls, $event.target['checked'])" />
              </div>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'numeric'">
          <ng-template kendoGridCellTemplate let-col>
            <div [style]="column?.style">
              {{
                column?.attributes !== null
                  ? commonFunction.formatNumeric(
                      col[column.field],
                      column?.attributes['precision'],
                      column?.attributes['fixedDecimal']
                    )
                  : col[column.field]
              }}
            </div>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              <div [style]="column?.style">
                {{
                  column?.attributes !== null
                    ? commonFunction.formatNumeric(
                        formGroup.get(column.field).value,
                        column?.attributes['precision'],
                        column?.attributes['fixedDecimal']
                      )
                    : formGroup.get(column.field).value
                }}
              </div>
            </div>
            <ng-template #elseBlock>
              <kendo-numerictextbox
                [formControl]="formGroup.get(column.field)"
                [format]="
                  column?.attributes !== null
                    ? 'n'.concat(column?.attributes['fixedDecimal'] !== null ? column?.attributes['fixedDecimal'] : '2')
                    : null
                ">
              </kendo-numerictextbox>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'text'">
          <div *ngIf="!column.inputMask">
            <ng-template kendoGridCellTemplate let-col>
              {{ col[column.field] }}
            </ng-template>
          </div>
          <div *ngIf="column.inputMask">
            <ng-template kendoGridCellTemplate let-col>
              <kendo-maskedtextbox
                title="column.title"
                class="k-textbox text-editor"
                [value]="col[column.field]"
                [mask]="column.inputMask">
              </kendo-maskedtextbox>
            </ng-template>
          </div>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              {{ formGroup.get(column.field).value }}
            </div>
            <ng-template #elseBlock>
              <div
                *ngIf="
                  column.options === null || column.options === undefined || column.options.length === 0;
                  else elseSelectBlock
                ">
                <div *ngIf="!column.inputMask">
                  <input
                    title="column.title"
                    type="text"
                    class="k-textbox text-editor"
                    [formControl]="formGroup.get(column.field)"
                    [disabled]="column.controlDisabled ? column.controlDisabled : null" />
                </div>
                <div *ngIf="column.inputMask">
                  <kendo-maskedtextbox
                    title="column.title"
                    class="k-textbox text-editor"
                    [formControl]="formGroup.get(column.field)"
                    [disabled]="column.controlDisabled ? column.controlDisabled : null"
                    [mask]="column.inputMask">
                  </kendo-maskedtextbox>
                </div>
              </div>
              <ng-template #elseSelectBlock>
                <div>
                  <kendo-combobox
                    class="combobox-editor"
                    [clearButton]="true"
                    [data]="column.options"
                    [formControl]="formGroup.get(column.field)"
                    [readonly]="column?.controlDisabled"
                    [ngModelOptions]="{ updateOn: 'change' }"
                    (valueChange)="
                      column?.controlChangeEvent ? column.controlChangeEvent($event, formGroup) : undefined
                    ">
                  </kendo-combobox>
                  <span class="inline-error">
                    <ng-container *ngIf="formGroupControls[column.field]?.errors">
                      <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                        {{ errorMessage[error.key] }}
                      </ng-container>
                    </ng-container>
                  </span>
                </div>
              </ng-template>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'select'">
          <ng-template kendoGridCellTemplate let-col>
            <kendo-combobox
              class="hide-box"
              [data]="column.options"
              [value]="getDisplayValue(col[column.field], column)"
              [readonly]="true"
              [valueField]="column.valueField"
              [textField]="column.textField">
            </kendo-combobox>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              <kendo-combobox
                class="hide-box"
                [data]="column.options"
                [value]="getDisplayValue(formGroup.get(column.field).value, column)"
                [readonly]="true"
                [valueField]="column.valueField"
                [textField]="column.textField">
              </kendo-combobox>
            </div>
            <ng-template #elseBlock>
              <kendo-combobox
                class="combobox-editor"
                [data]="column.options"
                [formControl]="formGroup.get(column.field)"
                [readonly]="column?.controlDisabled"
                (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent($event, formGroup) : undefined"
                [valueField]="column.valueField"
                [textField]="column.textField">
              </kendo-combobox>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'selectWithId'">
          <ng-template kendoGridCellTemplate let-col>
            <kendo-combobox
              [data]="column.options"
              [value]="getItemFromOptions(col[column.field], column.options, column?.valueField)"
              [readonly]="true"
              [valueField]="column.valueField"
              [textField]="column.textField">
            </kendo-combobox>
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              <kendo-combobox
                [data]="column.options"
                [value]="getItemFromOptions(formGroup.get(column.field).value, column.options, column?.valueField)"
                [readonly]="true"
                [valueField]="column.valueField"
                [textField]="column.textField">
              </kendo-combobox>
            </div>
            <ng-template #elseBlock>
              <kendo-combobox
                [data]="column.options"
                [formControl]="formGroup.get(column.field)"
                [readonly]="column?.controlDisabled"
                (valueChange)="column?.controlChangeEvent ? column.controlChangeEvent($event, formGroup) : undefined"
                [valueField]="column.valueField"
                [textField]="column.textField">
              </kendo-combobox>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'multiSelect'">
          <ng-template kendoGridCellTemplate let-col>
            <kendo-multiselect
              class="hide-box"
              [data]="column.options"
              [value]="getDisplayValue(col[column.field], column)"
              [readonly]="true"
              [valueField]="column.valueField"
              [textField]="column.textField">
            </kendo-multiselect>
          </ng-template>
          <ng-template kendoGridEditTemplate let-col let-formGroup="formGroup">
            <div *ngIf="column.editable === false; else elseBlock">
              <kendo-multiselect
                class="hide-box"
                [data]="column.options"
                [value]="getDisplayValue(col[column.field], column)"
                [readonly]="true"
                [valueField]="column.valueField"
                [textField]="column.textField">
              </kendo-multiselect>
            </div>
            <ng-template #elseBlock>
              <div>
                <kendo-multiselect
                  [data]="column.options"
                  [valueField]="column.valueField"
                  [textField]="column.textField"
                  [formControlName]="column.field"
                  (valueChange)="multiSelectChangeEvent($event, column.field)">
                </kendo-multiselect>
              </div>
              <span class="inline-error">
                <ng-container *ngIf="formGroupControls[column.field]?.errors">
                  <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                    {{ errorMessage[error.key] }}
                  </ng-container>
                </ng-container>
              </span>
            </ng-template>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'button'">
          <ng-template kendoGridCellTemplate let-rowIndex="rowIndex" let-col>
            <button
              *ngIf="column.iconType === undefined"
              kendoButton
              type="button"
              look="flat"
              [disabled]="column?.controlDisabled"
              (click)="column.controlClickEvent(col, rowIndex)"
              id="columnTypeBtn">
              {{ column.field }}
            </button>
            <button
              *ngIf="column.iconType !== undefined"
              kendoButton
              type="button"
              [disabled]="column?.controlDisabled"
              (click)="column.controlClickEvent(col, rowIndex)"
              id="columnTypeBtnIcon"
              title="Column Button"
              class="icon-button">
              <kendo-svgicon [icon]="allIcons[column.iconType]"></kendo-svgicon>
            </button>
          </ng-template>
          <ng-template kendoGridEditTemplate let-rowIndex="rowIndex" let-col>
            <button
              *ngIf="column.iconType === undefined"
              kendoButton
              type="button"
              look="flat"
              [disabled]="!column?.editable || column?.controlDisabled"
              (click)="column.controlClickEvent(col, rowIndex)"
              id="columnTypeBtnEdit">
              {{ column.field }}
            </button>
            <button
              *ngIf="column.iconType !== undefined"
              kendoButton
              type="button"
              [disabled]="!column?.editable || column?.controlDisabled"
              (click)="column.controlClickEvent(col, rowIndex)"
              class="icon-button"
              id="columnTypeBtnIconEdit"
              title="Column Button Edit">
              <kendo-svgicon [icon]="allIcons[column.iconType]"></kendo-svgicon>
            </button>
          </ng-template>
        </ng-container>

        <ng-container *ngIf="column.type === 'lookup'">
          <ng-template kendoGridCellTemplate let-col>
            {{ col[column.field] }}
          </ng-template>
          <ng-template kendoGridEditTemplate let-formGroup="formGroup">
            <input
              title="Lookup"
              type="text"
              [formControl]="formGroup.get(column.field)"
              class="k-textbox text-editor"
              readonly
              [ngModel]="column?.manualDisplayValue"
              (ngModelChange)="column?.controlChangeEvent ? column.controlChangeEvent($event, formGroup) : undefined"
              [value]="column?.manualDisplayValue ? column.manualDisplayValue : undefined" />
            <a kendoButton (click)="column.controlClickEvent()" class="look-over-btn-inline"></a>
            <span class="inline-error">
              <ng-container *ngIf="formGroupControls[column.field]?.errors">
                <ng-container *ngFor="let error of formGroupControls[column.field].errors | keyvalue">
                  {{ errorMessage[error.key] }}
                </ng-container>
              </ng-container>
            </span>
          </ng-template>
        </ng-container>
      </kendo-grid-column>

      <kendo-grid-command-column
        *ngIf="column.type === 'command'"
        [headerClass]="'command'"
        title="Command"
        [reorderable]="false"
        [includeInChooser]="false"
        class="command-column"
        [width]="getCommandColWidth()">
        <ng-template
          kendoGridCellTemplate
          let-isNew="isNew"
          let-rowIndex="rowIndex"
          let-columnIndex="columnIndex"
          let-dataItem>
          <div kendoTooltip [showAfter]="1500">
            <kendo-button
              kendoGridEditCommand
              *ngIf="inlineEdit || modalEdit"
              look="flat"
              title="Edit"
              [svgIcon]="edit"
              id="editBtnCmdColType"
              class="command-icon-button"
              (click)="
                rowClickHandler({ isEdited: true, dataItem: dataItem, rowIndex: rowIndex, columnIndex: columnIndex })
              ">
            </kendo-button>
            <kendo-button
              kendoGridRemoveCommand
              *ngIf="inlineDelete"
              look="flat"
              title="Delete"
              [svgIcon]="deleteIcon"
              id="deleteBtnCmdColType"
              class="command-icon-button"
              (click)="deleteRowCommandCol(dataItem)">
            </kendo-button>
            <kendo-button
              kendoGridSaveCommand
              look="flat"
              title="Save"
              [svgIcon]="save"
              id="saveBtnCmdColType"
              class="command-icon-button">
            </kendo-button>
            <kendo-button
              kendoGridCancelCommand
              look="flat"
              title="Cancel"
              [svgIcon]="cancel"
              id="cancelBtnCmdColType"
              class="command-icon-button">
            </kendo-button>
            <kendo-button
              *ngIf="inlineCustom"
              look="flat"
              title="{{ customBtnCmdColTitle }}"
              id="customBtnCmdColType"
              class="command-icon-button k-flat k-button"
              (click)="customEmit(dataItem)">
              <span class="k-icon k-i-{{ customBtnCmdColIcon }}"></span>
            </kendo-button>
          </div>
        </ng-template>
      </kendo-grid-command-column>
    </ng-container>

    <!-- SEARCH INDICATOR -->
    <ng-template *ngIf="loading === true" kendoGridNoRecordsTemplate>
      <p>Searching for records...</p>
    </ng-template>

    <!-- EXCEL -->
    <kendo-grid-excel [fileName]="excelFileName"></kendo-grid-excel>
  </kendo-grid>

  <!-- COMMON MODAL -->
  <CDMS-common-grid-modal-control
    *ngIf="showModal"
    [show]="showModal"
    [columns]="columns"
    [headers]="modalHeaders"
    [title]="title"
    [formGroup]="formGroup"
    [isNew]="isNew"
    [editEvent]="modalEditEvent"
    [onLoad]="modalOnLoad"
    (saveEvent)="modalSave($event)"
    (cancelEvent)="modalCancel()"
    (updateEvent)="modalUpdate($event)">
  </CDMS-common-grid-modal-control>
</div>
