import { Component, ElementRef, EventEmitter, HostListener, Input, Output, ViewChild } from '@angular/core';
import { Align, AnimationDirection, AnimationType, PopupAnimation } from '@progress/kendo-angular-popup';
import { questionCircleIcon } from '@progress/kendo-svg-icons';

import { Direction, Size, Type } from '../constants/common-type-constants';

@Component({
  // tslint:disable-next-line:component-selector
  selector: 'CDMS-common-info-slideout-control',
  templateUrl: './common-info-slideout-control.component.html',
  styleUrls: ['../assets/scss/_common-components.scss'],
})
export class CommonInfoSlideoutControlComponent {
  @Input() title: string;
  @Input() text: string;
  @Input() enabled = true;

  @Input()
  public set type(data: Type) {
    this._type = data;
  }
  public get type() {
    return this._type;
  }

  @Input()
  public set iconSize(data: Size) {
    this._iconSize = data;
  }
  public get iconSize() {
    return this._iconSize;
  }

  @Input()
  public set slideoutSize(data: Size) {
    this._slideoutSize = data;
  }
  public get slideoutSize() {
    return this._slideoutSize;
  }

  @Input()
  public set position(data: Direction) {
    const pos = data;
    switch (pos) {
      case 'top':
        this.anchorAlign = { horizontal: 'center', vertical: 'top' };
        this.popupAlign = { horizontal: 'center', vertical: 'bottom' };
        break;
      case 'left':
        this.anchorAlign = { horizontal: 'left', vertical: 'center' };
        this.popupAlign = { horizontal: 'right', vertical: 'center' };
        break;
      case 'right':
        this.anchorAlign = { horizontal: 'right', vertical: 'center' };
        this.popupAlign = { horizontal: 'left', vertical: 'center' };
        break;
      case 'bottom':
        this.anchorAlign = { horizontal: 'center', vertical: 'bottom' };
        this.popupAlign = { horizontal: 'center', vertical: 'top' };
        break;
      default:
        this.anchorAlign = { horizontal: 'center', vertical: 'bottom' };
        this.popupAlign = { horizontal: 'center', vertical: 'top' };
        break;
    }
  }

  @Input()
  public set animation(data: PopupAnimation) {
    this._animation = data;
  }
  public get animation() {
    return this._animation;
  }

  @Output() slideoutStatus = new EventEmitter();

  @HostListener('document:click', ['$event'])
  public documentClick(event: KeyboardEvent): void {
    if (!this.contains(event.target)) {
      this.toggle(false);
    }
  }

  @ViewChild('infoButton') public anchor: ElementRef;
  @ViewChild('infoPopup', { read: ElementRef }) public popup: ElementRef;

  public show = false;
  public anchorAlign: Align = { horizontal: 'center', vertical: 'bottom' };
  public popupAlign: Align = { horizontal: 'center', vertical: 'top' };
  public animationType: AnimationType = 'slide';
  public animationDir: AnimationDirection = 'down';
  public question = questionCircleIcon;

  private _iconSize: Size = 'medium';
  private _slideoutSize: Size = 'medium';
  private _type: Type = 'primary';
  private _animation: PopupAnimation = {
    type: this.animationType,
    direction: this.animationDir,
    duration: 500,
  };

  constructor() {}

  public toggle(status?: boolean): void {
    if (typeof status !== 'undefined') {
      this.show = status;
    } else {
      this.show = !this.show;
    }
    this.slideoutStatus.emit(this.show);
  }

  private contains(target: EventTarget): boolean {
    return (
      this.anchor?.nativeElement.contains(target) || (this.popup ? this.popup.nativeElement.contains(target) : false)
    );
  }

  public get getAnimation(): boolean | PopupAnimation {
    if (this.enabled) {
      return this._animation;
    }
    return false;
  }
}
