import {
  FileParameter,
  RequestType,
} from 'src/app/services/providerdirectoryapi/providerdirectoryfunctionapi.services';

export interface IUpload {
  userId?: string;
  requestType?: RequestType;
  item?: FileParameter;
}

export class Upload implements IUpload {
  userId?: string | undefined;
  requestType?: RequestType | undefined;
  item?: FileParameter | undefined;

  constructor(data?: IUpload) {
    if (data !== undefined) {
      for (const property in data) {
        if (data.hasOwnProperty(property)) {
          (<any>this)[property] = (<any>data)[property];
        }
      }
    }
  }

  init(_data?: any) {
    if (_data) {
      this.userId = _data['userId'];
      this.requestType = _data['requestType'];
      this.item = _data['item'];
    }
  }

  static fromJS(data: any): Upload {
    data = typeof data === 'object' ? data : {};
    const result = new Upload();
    result.init(data);
    return result;
  }

  toJSON(data?: any) {
    data = typeof data === 'object' ? data : {};
    data['userId'] = this.userId;
    data['requestType'] = this.requestType;
    data['item'] = this.item;
    return data;
  }
}
