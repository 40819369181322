<div class="page-wrapper">
  <div class="column-center">
    <div class="row-center filter-row p-t-20 search-fields">
      <p class="page-subtitle-2 m-r-20">{{ 'page_Search_FilterBy' | langchange }}:</p>

      <!-- FILTER ROW -->
      <form class="row-center" [formGroup]="searchForm">
        <div class="m-r-10">
          <kendo-formfield>
            <CDMS-common-input-control
              title="{{ 'title_Zipcode' | langchange }}"
              placeholder="XXXXX"
              [control]="$any(this.searchForm.get('zipCode'))"
              ngDefaultControl
              formControlName="zipCode"
              [input]="'text'"
              [size]="'small'"
              [value]="searchData.zipCode">
            </CDMS-common-input-control>
          </kendo-formfield>
        </div>
        <div>
          <kendo-formfield>
            <CDMS-common-input-control
              title="{{ 'title_Distance' | langchange }}"
              placeholder="{{ 'title_Select' | langchange }}"
              [control]="$any(this.searchForm.get('radius'))"
              ngDefaultControl
              formControlName="radius"
              [input]="'dropdown'"
              [size]="'small'"
              [options]="radiusOptions"
              [value]="searchData.radius">
            </CDMS-common-input-control>
          </kendo-formfield>
        </div>
        <div #searchButton class="filter-buttons-search">
          <CDMS-common-button-control
            title="{{ 'title_Search' | langchange }}"
            [titleHidden]="true"
            [button]="'icon'"
            [icon]="zoom"
            [size]="'medium'"
            [type]="'primary'"
            [plane]="'vertical'"
            [disabled]="searchForm.invalid"
            (clickEvent)="search()">
          </CDMS-common-button-control>
        </div>
        <div #moreButton class="filter-buttons-more">
          <CDMS-common-button-control
            title="{{ 'title_More' | langchange }}"
            [titleHidden]="true"
            [button]="'icon'"
            [icon]="moreHorizontal"
            [size]="'small'"
            [type]="'primary'"
            [plane]="'vertical'"
            (clickEvent)="toggleMorePopup()">
          </CDMS-common-button-control>
        </div>
        <div #clearButton class="filter-buttons-clear">
          <CDMS-common-button-control
            title="{{ 'title_ClearFilters' | langchange }}"
            [titleHidden]="true"
            [button]="'icon'"
            [icon]="filterClear"
            [size]="'small'"
            [type]="'primary'"
            [plane]="'vertical'"
            (clickEvent)="clearFilters()">
          </CDMS-common-button-control>
        </div>

        <!-- MORE POPUP -->
        <div *ngIf="showMore" class="more-popup">
          <div>
            <kendo-popup
              #morePopup
              class="more-popup-body"
              [anchor]="moreButton"
              [anchorAlign]="anchorAlign"
              [popupAlign]="popupAlign"
              [animate]="getAnimation"
              (anchorViewportLeave)="toggleMorePopup()">
              <div class="content">
                <div class="m-r-10">
                  <kendo-formfield>
                    <CDMS-common-input-control
                      title="{{ 'title_DayAvailable' | langchange }}"
                      placeholder="{{ 'title_Any' | langchange }}"
                      [control]="$any(this.searchForm.get('day'))"
                      ngDefaultControl
                      formControlName="day"
                      [input]="'dropdown'"
                      [size]="'small'"
                      [options]="lang === 'es' ? dayOptionsES : dayOptions"
                      [ngModel]="this.searchForm.get('day').value"
                      [value]="searchData.day">
                    </CDMS-common-input-control>
                  </kendo-formfield>
                </div>
                <div>
                  <kendo-formfield>
                    <CDMS-common-input-control
                      title="{{ 'title_WeeklyHours' | langchange }}"
                      [control]="$any(this.searchForm.get('hours'))"
                      ngDefaultControl
                      formControlName="hours"
                      [format]="'##+'"
                      [input]="'numeric'"
                      [size]="'small'"
                      [value]="searchData.hours">
                    </CDMS-common-input-control>
                  </kendo-formfield>
                </div>
              </div>
            </kendo-popup>
          </div>
        </div>
      </form>
    </div>

    <!-- SEARCH RESULT CARDS -->
    <div *ngIf="!showCaptcha" class="column search-results">
      <!-- Use [data] for server side -->
      <kendo-listview
        #listview
        [kendoListViewBinding]="searchResults"
        [pageable]="showPager"
        [pageSize]="pageSize"
        [loading]="loadSearch">
        <ng-template kendoListViewHeaderTemplate>
          <div *ngIf="searchResults.length === 0 && isSearched" class="no-search-results">
            {{ 'title_NoResults' | langchange }}
          </div>
        </ng-template>

        <ng-template kendoListViewItemTemplate *ngIf="!loadSearch" let-dataItem="dataItem" let-isFirst="isFirst">
          <div class="m-b-20">
            <CDMS-attendant-card-control [details]="dataItem" (clickEvent)="goProfile($event)">
            </CDMS-attendant-card-control>
          </div>
        </ng-template>

        <ng-template kendoListViewFooterTemplate> </ng-template>

        <ng-template kendoListViewLoaderTemplate>
          <CDMS-common-load-indicator-control [show]="loadSearch"> </CDMS-common-load-indicator-control>
        </ng-template>
      </kendo-listview>
    </div>
  </div>
</div>

<!-- CAPTCHA-->
<div *ngIf="showCaptcha">
  <div class="captcha-wrapper">
    <CDMS-captcha-control (errorEvent)="captchaResponse(false, $event)" (resolved)="captchaResponse(true, $event)">
    </CDMS-captcha-control>
  </div>
</div>
