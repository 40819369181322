<ion-app>
  <CDMS-appbar-control class="appbar"> </CDMS-appbar-control>

  <ion-router-outlet></ion-router-outlet>

  <div
    *ngIf="load.certs || load.langPref || load.servs || load.user || load.workPref || load.adminApp || load.attProf"
    class="site-load">
    <CDMS-common-load-indicator-control
      class="site-load-indicator"
      [show]="load.certs || load.langPref || load.servs || load.user || load.workPref || load.adminApp || load.attProf">
    </CDMS-common-load-indicator-control>
  </div>
</ion-app>
