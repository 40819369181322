import { Pipe, PipeTransform } from '@angular/core';

import { AppConstants } from '../../constants/app-constants';

@Pipe({
  name: 'nulldate',
  pure: false,
})
export class NullDate implements PipeTransform {
  constructor() {}

  transform(date: any): any {
    if (date === '01/01/0001') {
      return '';
    }
    return date;
  }
}
